import { createRef, useCallback, useEffect, useState } from 'react';
import { Card } from './MiniChart';
import InfiniteLoader from 'react-window-infinite-loader';
import AppPage from '../../AppPage';
import { useAppContext, useChartContext } from '../../../contexts';
import UtilityCard from "../UtilityCard.tsx";
import { FixedSizeList } from 'react-window';
import { FaPlus } from 'react-icons/fa';
import { Route, Routes, useNavigate } from 'react-router-dom';
import GanttChart from './GanttChart'
import ChartCreate from './ChartCreate.tsx';

const BOTTOM_MARGIN = 148;

const ChartPage: React.FC = () => {
    const { width, height } = useAppContext();
    const [contentHeight, setHeight] = useState(height);
    const [contentWidth, setWidth] = useState(width);

    const outerDivRef = createRef<HTMLDivElement>();
    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setHeight(rect.height - BOTTOM_MARGIN);
            setWidth(rect.width);
        }
    }, [outerDivRef, width, height]);

    const navigate = useNavigate();
    const { charts } = useChartContext();

    const handleUtilClick = useCallback(() => {
        navigate("/app/charts/create");
    }, [navigate]);

    const [utilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText] = useState("Upload Transcripts");

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < charts.length;
    }, [charts.length]);

    const itemData = {
        items: charts,
        handleClick: (id: string) => navigate(`/app/charts/${id}`),
    };

    return <div className="flex-col max-w-lg" ref={outerDivRef}>
        <h1 className="ml-4">Plans</h1>
        <UtilityCard
            handleCardClick={handleUtilClick}
            icon={utilIcon}
            style={{ width: "calc(100% - 32px)" }}
            cardText={utilText}
        />
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={charts.length}
            loadMoreItems={() => { }}
        >
            {({ onItemsRendered, ref }) => (
                <FixedSizeList
                    className="scroll-list"
                    height={contentHeight}
                    width={contentWidth}
                    itemSize={160}
                    itemData={itemData}
                    itemCount={charts.length}
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {Card}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    </div>
};

function ChartWindow() {
    return (
        <AppPage active="charts">
            <Routes>
                <Route path="/" element={<ChartPage />} />
                <Route path="create" element={<ChartCreate />} />
                <Route path=":id" element={<GanttChart />} />
            </Routes>
        </AppPage>
    );
}

export default ChartWindow;
