import { useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import Floater from "./Floater";
import { eventEmitter, useAppContext } from "../contexts";

function RecordButton() {
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
    const { sendWebMessage, socketIsConnected } = useAppContext();

    async function startRecording() {
        if (!socketIsConnected()) {
            return;
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const options = { mimeType: "audio/webm;codecs=opus" };
        const recorder = new MediaRecorder(stream, options);
        recorder.ondataavailable = (e) => {
            const audioBlob = e.data;
            sendWebMessage(audioBlob);
        };
        recorder.start();
        setRecorder(recorder);
        eventEmitter.emit("INSTRUCT-OPEN");
    }

    function stopRecording() {
        if (recorder) {
            recorder.stop();
            setRecorder(null);
        }
    }

    const isRecording = recorder !== null;
    // Disabled for the beta release
    return (
        <div
            hidden={true}
            onClick={isRecording ? stopRecording : startRecording}
            className={`omnibar__icon omnibar__glow ${isRecording ? "text-verdigris-500 dark:text-aquamarine-400" : ""}`}
        >
            <Floater content="Chat with the AI assistant">
                <FaMicrophone />
            </Floater>
        </div>
    );
}

export default RecordButton;
