import AppPage from '../../AppPage';
import ProjectGrid from './ProjectGrid';
import ProjectDetailsView from './ProjectDetailsView';
import { Routes, Route } from 'react-router-dom'
import { useAppContext, useProjectsContext } from '../../../contexts';
import { createRef, useEffect, useState } from 'react';

function ProjectsView() {
    const { width, height } = useAppContext();
    const {
        projects,
        orgProjects,
    } = useProjectsContext();

    const personalDivRef = createRef<HTMLDivElement>();
    const teamDivRef = createRef<HTMLDivElement>();

    const [personalWidth, setPersonalWidth] = useState(width);
    const [personalHeight, setPersonalHeight] = useState(height);
    const [teamWidth, setTeamWidth] = useState(width);
    const [teamHeight, setTeamHeight] = useState(height);

    useEffect(() => {
        if (personalDivRef.current) {
            const rect = personalDivRef.current.getBoundingClientRect();
            setPersonalWidth(rect.width);
            setPersonalHeight(rect.height);
        }
        if (teamDivRef.current) {
            const rect = teamDivRef.current.getBoundingClientRect();
            setTeamWidth(rect.width);
            setTeamHeight(rect.height);
        }
    }, [personalDivRef, teamDivRef, width, height]);

    return (
        <div className="flex p-6 w-full">
            <div className='w-1/2 max-w-lg' id="personalProjectGrid" ref={personalDivRef}>
                <h2 className="text-center">Personal</h2>
                <div className="flex h-8"></div>
                <ProjectGrid projects={projects} containerWidth={personalWidth} containerHeight={personalHeight} projectSource="personal" />
            </div>
            <div className='w-1/2 max-w-lg' id="teamProjectGrid" ref={teamDivRef}>
                <h2 className="text-center">Team</h2>
                <div className="flex h-8"></div>
                <ProjectGrid projects={orgProjects} containerWidth={teamWidth} containerHeight={teamHeight} projectSource="organization" />
            </div>
        </div>
    )
}

function ProjectWindow() {
    return (
        <AppPage active="projects">
            <Routes>
                <Route path="/" element={<ProjectsView />} />
                <Route path=":id" element={<ProjectDetailsView />} />
            </Routes>
        </AppPage>
    );
}

export default ProjectWindow;
