import { FaUsers, FaTasks, FaDatabase, } from "react-icons/fa";
import { FaMessage, FaDiagramProject, FaRegChartBar } from "react-icons/fa6";
import { TbReport } from "react-icons/tb";
import { Milestone } from "lucide-react";
import { useAppContext, useInstructionContext, useProjectsContext } from "../contexts";
import subseqLogo from "../assets/SUBSEQ-logo-long.png";
import subseqLogoMobile from "../assets/SUBSEQ-logo-tight.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Separator } from "./utils/Separator";
import ProjectSelect from "./ProjectSelect";

interface SideBarProps {
    active: string;
}

function SideBar({ active }: SideBarProps) {
    const { loggedInUser, mobile, narrow } = useAppContext();
    const { setConversationOpen, conversationOpen } = useInstructionContext();
    const { activeProject } = useProjectsContext();
    const navigate_ = useNavigate();

    function openInstruction() {
        setConversationOpen(!conversationOpen);
    }

    function navigate(path: string) {
        if (narrow) {
            setConversationOpen(false);
        }
        navigate_(path);
    }

    function navigateHome() {
        navigate("/");
    }

    const [logo, setLogo] = useState(mobile ? subseqLogoMobile : subseqLogo);
    const [divClass, setDivClass] = useState(mobile ? "flex ml-auto" : "");
    const [logoClass, setLogoClass] = useState(mobile ? "h-12 w-12 m-0" : "h-12");
    const [logoImageClass, setLogoImageClass] = useState(mobile ?
        "sidebar__logo-img cursor-pointer" :
        "sidebar__logo-img cursor-pointer mt-4");

    useEffect(() => {
        setLogo(mobile ? subseqLogoMobile : subseqLogo);
        setDivClass(mobile ? "ml-2 flex w-full" : "");
        setLogoClass(mobile ? "h-12 w-16" : "h-12");
        setLogoImageClass(mobile ?
            "sidebar__logo-img cursor-pointer mt-1 ml-1 w-12 h-12" :
            "sidebar__logo-img cursor-pointer mt-4"
        );
    }, [mobile]);

    if (!loggedInUser) {
        return null;
    }

    const iconClass = mobile ? "mr-3 ml-3" : "mr-auto ml-2";

    return (
        <div className="sidebar relative">
            <div className={logoClass}>
                <img className={logoImageClass} onClick={navigateHome} src={logo} />
            </div>
            <div className={divClass}>
                {!mobile && <>
                    {!mobile && <Separator />}
                    <ProjectSelect activeProject={activeProject} />
                    {!mobile && <Separator />}
                </>}
                <SideBarIcon
                    name="tasks"
                    active={active}
                    icon={<FaTasks className={iconClass} size="24" />}
                    text="Tasks"
                    fn={() => navigate("/app/tasks")}
                    mobile={mobile}
                />
                <SideBarIcon
                    name="projects"
                    active={active}
                    icon={<FaDiagramProject className={iconClass} size="24" />}
                    text="Projects"
                    fn={() => navigate("/app/projects")}
                    mobile={mobile}
                />
                <SideBarIcon
                    name="users"
                    active={active}
                    icon={<FaUsers className={iconClass} size="24" />}
                    text="Team"
                    fn={() => navigate("/app/users")}
                    mobile={mobile}
                />
                <SideBarIcon
                    name="charts"
                    active={active}
                    icon={<FaRegChartBar className={iconClass} size="24" />}
                    text="Planning"
                    fn={() => navigate("/app/charts")}
                    mobile={mobile}
                />
                <SideBarIcon
                    name="milestones"
                    active={active}
                    icon={<Milestone className={iconClass} size="24" />}
                    text="Milestones"
                    fn={() => navigate("/app/milestones")}
                    mobile={mobile}
                />
                <SideBarIcon
                    name="data"
                    active={active}
                    icon={<FaDatabase className={iconClass} size="24" />}
                    text="Transcripts"
                    fn={() => navigate("/app/data")}
                    mobile={mobile}
                    disabled={true}
                />
                <SideBarIcon
                    name="reports"
                    active={active}
                    icon={<TbReport className={iconClass} size="24" />}
                    text="Reports"
                    fn={() => navigate("/app/reports")}
                    mobile={mobile}
                    disabled={true}
                />
            </div>
            <div className={mobile ? "" : "sidebar__lower"}>
                {!mobile && <Separator />}
                <SideBarIcon
                    name="instruct"
                    active={active}
                    icon={<FaMessage className={iconClass} size="24" />}
                    text="AI Chat"
                    fn={openInstruction}
                    mobile={mobile}
                />
            </div>
        </div>
    );
}

type VoidFunction = () => void;

interface SideBarIconProps {
    name: string;
    active: string;
    icon: React.ReactNode;
    fn?: VoidFunction;
    text?: string;
    mobile: boolean;
    disabled?: boolean;
}

const SideBarIcon = ({
    name,
    active,
    icon,
    fn = () => { },
    text = "tooltip ☁️",
    mobile,
    disabled
}: SideBarIconProps) => {
    if (disabled) {
        if (mobile) {
            return <> </>;
        }
        return (<div className="sidebar__icon--disabled">
            {icon}
            {<span className="m-2 text-sm">{text}</span>}
        </div>);
    }

    return (<div
        id={`${name}SidebarIcon`}
        onClick={fn}
        className={`${name === active ? "sidebar__icon--active" : "sidebar__icon"} group`}
    >
        {icon}
        {!mobile && <span className="m-2 text-sm">{text}</span>}
    </div >);
}

export default SideBar;
