import React, { memo, useRef } from "react";
import { Transcript } from "../../../contexts/Data";
import { ListChildComponentProps, areEqual } from "react-window";
import { FaVoicemail } from "react-icons/fa";
import Personelle from "../../Personelle";
import ReactTimeAgo from "react-time-ago";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, handleClick } = data;
    const dataItem = items[index];
    if (!dataItem) {
        return null;
    }
    const height = style.height as number;

    const dynamicStyle = {
        ...style,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniData
        key={`data-${dataItem.id}`}
        handleClick={() => handleClick(dataItem.id)}
        card={dataItem}
        style={dynamicStyle}
    />);
}, areEqual);

interface MiniDataProps {
    handleClick: () => void;
    card: Transcript;
    style: React.CSSProperties
}

function MiniData({ handleClick, card, style }: MiniDataProps) {
    const ref = useRef<HTMLDivElement>(null);
    const createdDate = new Date(card.createdAt + "Z");

    return (
        <div ref={ref} style={style} onClick={handleClick} className="card">
            <div className="card__top-bar">
                <div className="card__icon">
                    <FaVoicemail />
                </div>
                <div className="card-header">{card.title}</div>
            </div>
            <Personelle userData={card.createdBy} tag="Uploaded by" />
            <div className="flex">
                <div className="flex-grow" />
                <ReactTimeAgo className="mr-2" date={createdDate} />
            </div>
        </div>
    );
}
