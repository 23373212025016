import { useCallback, useEffect, useState } from "react";
import { Card } from "./MiniTask";
import { isTaskData, CardData } from "../CardDataInterfaces.tsx";
import { useItemFilter } from "../CardGrid.tsx";
import { eventEmitter, useTasksContext } from "../../../contexts";
import { TaskData } from "../../../contexts/Task";
import { FaArchive, FaPlus, FaRegWindowClose } from "react-icons/fa";
import UtilityCard from "../UtilityCard.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { DropTargetMonitor } from "react-dnd";

import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";

const BOTTOM_MARGIN = 40;
const UTILITY_MARGIN = 184;

interface TaskGridProps {
    containerWidth: number;
    containerHeight: number;
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
    filter: (searchTerm: string, items: TaskData[]) => TaskData[];
    showUtilityCard: boolean;
    autoOpenTask: boolean;
    dropRule: (card: TaskData) => (item: unknown, monitor: DropTargetMonitor) => void;
    setVisibleTasks?: (tasks: Set<string>) => void;
    hasTaskIcon?: boolean;
}


function TaskGrid({ containerWidth, containerHeight, dragging, setDragging, filter, showUtilityCard, autoOpenTask, dropRule, setVisibleTasks, hasTaskIcon }: TaskGridProps) {
    const navigate = useNavigate();
    const {
        tasks,
        indexesById,
        loadNextPage,
        hasNextPage,
        addTask,
        closeTask,
        archiveTask,
        updateTaskItem,
        fetchBySearch,
    } = useTasksContext();

    const [searchPage, setSearchPage] = useState(1);
    const { setItems, searchTerm, setSearchTerm, filteredItems } = useItemFilter<TaskData>({ filter });
    useEffect(() => { setItems(tasks); }, [tasks, setItems]);
    useEffect(() => { setSearchPage(1); }, [searchTerm]);

    useEffect(() => {
        if (searchTerm.length > 2 && filteredItems.length < 50 && searchPage === 1) {
            fetchBySearch(searchTerm, searchPage);
            setSearchPage(searchPage + 1);
        }
    }, [filteredItems.length, searchTerm, searchPage, fetchBySearch]);

    const loadMoreItems = useCallback(() => {
        if (searchTerm.length > 2) {
            fetchBySearch(searchTerm, searchPage);
            setSearchPage(searchPage + 1);
        } else {
            loadNextPage();
        }
    }, [searchTerm, searchPage, loadNextPage, fetchBySearch]);

    useEffect(() => {
        eventEmitter.on("SEARCH-FILTER", setSearchTerm);
        return () => {
            eventEmitter.off("SEARCH-FILTER", setSearchTerm);
        };
    }, [addTask, updateTaskItem, setSearchTerm]);

    const handleUtilClick = useCallback(() => {
        navigate("/app/tasks/create");
    }, [navigate]);

    const handleUtilDrop = useCallback((card: CardData) => {
        if (isTaskData(card) && card.abstractState !== "closed") {
            closeTask(card);
            setDragging(null);
        } else if (isTaskData(card) && card.abstractState === "closed") {
            archiveTask(card);
            setDragging(null);
        }
    }, [archiveTask, closeTask, setDragging]);

    const location = useLocation();
    const handleClick = useCallback((id: string) => {
        const idx = indexesById[id];
        const task = tasks[idx];

        const maybeSlug = location.pathname.split("/").pop();
        if (!maybeSlug) {
            navigate(`/app/tasks/${task.slug}`);
            return;
        }
        const slug = decodeURI(maybeSlug);
        if (slug === task.slug) {
            return;
        }
        navigate(`/app/tasks/${task.slug}`);
    }, [tasks, navigate, indexesById, location]);

    useEffect(() => {
        if (autoOpenTask) {
            const maybeId = location.pathname.split("/").pop();
            if (filteredItems.length === 1 && maybeId !== 'create') {
                handleClick(filteredItems[0].id);
            }
        }
    }, [filteredItems, handleClick, location.pathname, autoOpenTask]);

    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText, setUtilText] = useState("Create Task");

    useEffect(() => {
        if (dragging) {
            if (dragging.abstractState !== "closed") {
                setUtilIcon(<FaRegWindowClose className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
                setUtilText("Close Task");
            } else {
                setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
                setUtilText("Archive Task");
            }
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText("Create Task");
        }
    }, [dragging]);

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < filteredItems.length || !hasNextPage();
    }, [filteredItems.length, hasNextPage]);

    let height = containerHeight - BOTTOM_MARGIN;
    if (showUtilityCard) {
        height -= UTILITY_MARGIN;
    }
    const width = containerWidth;

    useEffect(() => {
        if (setVisibleTasks) {
            setVisibleTasks(new Set(filteredItems.map((item) => item.id)));
        }
    }, [filteredItems, setVisibleTasks]);

    const itemData = {
        items: filteredItems,
        dragging,
        handleClick,
        setDragging,
        dropRule,
        hasTaskIcon,
    };

    const children = (
        <div className="flex-col">
            {showUtilityCard && <UtilityCard
                handleCardClick={handleUtilClick}
                handleCardDrop={handleUtilDrop}
                dragging={dragging}
                icon={utilIcon}
                style={{ width: "calc(100% - 32px)" }}
                cardText={utilText}
                showSort={true}
            />}
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={hasNextPage() ? filteredItems.length + 1 : filteredItems.length}
                loadMoreItems={loadMoreItems}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        className="scroll-list"
                        height={height}
                        itemSize={160}
                        width={width}
                        itemData={itemData}
                        itemCount={filteredItems.length}
                        onItemsRendered={onItemsRendered}
                        ref={ref} >
                        {Card}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        </div>
    );
    return children;
}

export default TaskGrid;
