import React from 'react';
import { FaVoicemail } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import DetailGrid, {
    DetailContentArea,
} from "../DetailGrid";
import { useNavigate } from 'react-router-dom';
import { useDataContext } from '../../../contexts';

function DataCreate() {
    const { addTranscripts } = useDataContext();
    const navigate = useNavigate();
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            addTranscripts(event.target.files);
        }
    }

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaVoicemail />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Upload Transcripts</h2>
                </div>
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <form id="UploadFiles" className="onboarding__form">
                        <input className="hidden" type="file" accept="text/plain, text/html, application/json" id="upload-files" onChange={handleFileUpload} />
                        <label className="image-upload__button" htmlFor="upload-files">Upload</label>
                    </form >
                </DetailContentArea>
            </DetailGrid>
        </div>);
}
export default DataCreate;
