import React from "react";
import { ProviderProps } from "./ContextTypes";
import { TaskUpdate } from "./Task";
import { eventEmitter, isDevelopment, useAppContext } from ".";

export type Content = { text: string } | { object: object };
export type MessageState = "ongoing" | "message";
export interface Chat {
    role: string;
    content: Content;
    state?: MessageState;
}

export interface ConsentTask {
    id: string;
    title: string;
    description: string;
    tags: string[];
    components: string[];
}

export interface ConsentTaskUpdateWrapper {
    task: ConsentTask;
    update: TaskUpdate | string;
}

export interface ConsentProject {
    title: string;
    description: string;
}
export type ConsentRequest = { task: ConsentTask } | { taskUpdate: ConsentTaskUpdateWrapper } | { project: ConsentProject };

export interface Consent {
    consentId: string;
    payload: ConsentRequest
}

export interface TipPayload {
    content: string;
    icon?: string;
}

export interface Tip {
    tipId: string;
    payload: TipPayload;
}

export type Message = Chat | Consent | Tip;

export interface InstructionContextProps {
    messages: Message[];
    addMessage: (message: Message) => void;
    clearMessages: () => void;
    onAcceptConsent: (index: number, content: string) => void;
    onRejectConsent: (index: number) => void;
    onAcceptAllConsent: () => void;
    onRejectAllConsent: () => void;

    conversationOpen: boolean;
    setConversationOpen: (open: boolean) => void;
}

export const InstructionContext = React.createContext<InstructionContextProps | undefined>(undefined);

function acceptString(payload: ConsentRequest): string {
    if ("task" in payload) {
        return `Created ${payload.task.title}`;
    } else if ("taskUpdate" in payload) {
        return `Updated ${payload.taskUpdate.task.id}`;
    } else if ("project" in payload) {
        return `Created ${payload.project.title}`;
    } else {
        return "";
    }
}

const InstructionProvider: React.FC<ProviderProps> = ({ children }) => {
    const { sendWebMessage, socketIsConnected } = useAppContext();
    const [conversationOpen, setConversationOpen] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<Message[]>([]);

    const addMessage = React.useCallback((msg: Message) => {
        setMessages((currentItems) => [...currentItems, msg]);
    }, []);

    const clearMessages = React.useCallback(() => {
        setMessages([]);
    }, []);

    React.useEffect(() => {
        if (isDevelopment) {
            setMessages([
                { tipId: "0", payload: { "icon": "lightbulb", "content": "This is a tip! It has a lot of words so we can see what overlapping looks like" } },
                { tipId: "1", payload: { "icon": "console", "content": "This is a tip! It has a lot of words so we can see what overlapping looks like" } },
                { tipId: "2", payload: { "icon": "pen", "content": "This is a tip! It has a lot of words so we can see what overlapping looks like" } },
                { role: "user", content: { text: "Hello" } },
                { role: "assistant", content: { text: "Hi there!" } },
                { role: "user", content: { text: "How are you?" } },
                { role: "assistant", content: { text: "I'm doing well, thank you!" } },
                { role: "error", content: { text: "This is a system message" } },
                { consentId: "0", payload: { project: { title: "Consent Project", description: "This is a project I would like to create" } } },
                { consentId: "1", payload: { task: { id: "1", title: "Consent Task", description: "This is a task I would like to create", tags: ["tag1", "tag2"], components: ["component1", "component2"] } } },
                { consentId: "2", payload: { task: { id: "2", title: "Consent Task 2", description: "This is a another task with an exceptionally long message so I can see what happens when the AI invariably types way too much to fit into the box. Here, we are writing so much text it's a wonder anyone would consider reading it all. For sure this is a crazy time for all of us to be blessed with this much conversation. But I hope it ends soon because we can't be overdoing ourselves in one go here! We need to parcel out the glorous text so we can truly savor the moment and enjoy every word as written. I hope you too will join me in welcoming this text to the screen.", tags: ["tag1", "tag2"], components: ["component1", "component2"] } } },
            ]);
        }
    }, []);

    const sendConsentMessage = React.useCallback((consentId: string, consented: boolean, content: string | null) => {
        if (!socketIsConnected) {
            console.error("Websocket is not connected");
            return;
        }
        sendWebMessage({ instruct: { consent: { consentId, message: content, state: consented ? "accept" : "deny" } } });
    }, [sendWebMessage, socketIsConnected]);

    const onAcceptAllConsent = React.useCallback(() => {
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if ("consentId" in message) {
                const consentId: string = message.consentId
                const response = acceptString(message.payload);
                sendConsentMessage(consentId, true, response);
            }
        }
        const remainingMessages = messages.filter((message) => !("consentId" in message));
        setMessages(remainingMessages);
    }, [messages, sendConsentMessage]);

    const onRejectAllConsent = React.useCallback(() => {
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            if ("consentId" in message) {
                const consentId: string = message.consentId
                sendConsentMessage(consentId, false, null);
            }
        }
        const remainingMessages = messages.filter((message) => !("consentId" in message));
        setMessages(remainingMessages);
    }, [messages, sendConsentMessage]);

    const onAcceptConsent = React.useCallback((index: number, content: string) => {
        const message = messages[index];
        if ("consentId" in message) {
            const consentId: string = message.consentId
            sendConsentMessage(consentId, true, content);
        }
        setMessages((messages) => {
            console.log("Replacing message at index", index);
            return [
                ...messages.slice(0, index),
                ...messages.slice(index + 1),
            ];
        });
    }, [messages, sendConsentMessage]);

    const onRejectConsent = React.useCallback((index: number) => {
        const message = messages[index];
        if ("consentId" in message) {
            const consentId: string = message.consentId
            sendConsentMessage(consentId, false, null);
        }
        setMessages((messages) => {
            console.log("Replacing message at index", index);
            return [
                ...messages.slice(0, index),
                ...messages.slice(index + 1),
            ];
        });
    }, [messages, sendConsentMessage]);

    React.useEffect(() => {
        eventEmitter.on("INSTRUCT-MESSAGE", addMessage);
        eventEmitter.on("INSTRUCT-CONSENT-REQUEST", addMessage);
        eventEmitter.on("INSTRUCT-CLEAR", clearMessages);

        return () => {
            eventEmitter.off("INSTRUCT-MESSAGE", addMessage);
            eventEmitter.off("INSTRUCT-CONSENT-REQUEST", addMessage);
            eventEmitter.off("INSTRUCT-CLEAR", clearMessages);
        };
    }, [addMessage, clearMessages]);

    const value = {
        messages,
        addMessage,
        clearMessages,
        conversationOpen,
        setConversationOpen,
        onAcceptConsent,
        onRejectConsent,
        onAcceptAllConsent,
        onRejectAllConsent,
    };
    return (<InstructionContext.Provider value={value}>
        {children}
    </InstructionContext.Provider>);
};
export default InstructionProvider;
