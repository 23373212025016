import React, { memo, useRef } from "react";
import { Milestone } from "../../../contexts/Milestone";
import { ListChildComponentProps, areEqual } from "react-window";
import ReactTimeAgo from "react-time-ago";
import { Label } from "../../utils/Label";
import { MilestoneIcon } from "lucide-react";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, handleClick } = data;
    const dataItem = items[index];
    if (!dataItem) {
        return null;
    }
    const height = style.height as number;

    const dynamicStyle = {
        ...style,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniData
        key={`data-${dataItem.id}`}
        handleClick={() => handleClick(dataItem.id)}
        card={dataItem}
        style={dynamicStyle}
    />);
}, areEqual);

interface MiniDataProps {
    handleClick: () => void;
    card: Milestone;
    style: React.CSSProperties
}

function MiniData({ handleClick, card, style }: MiniDataProps) {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div ref={ref} style={style} onClick={handleClick} className="card">
            <div className="card__top-bar">
                <div className="card__icon">
                    <MilestoneIcon size={24} />
                </div>
                <div className="card-header">{card.name}</div>
            </div>
            <div className="m-2 justify-between items-center">
                <Label className="mr-2">{card.milestoneType}</Label>
                <div className="flex-grow" />
                <Label>{card.completed ? "Completed" : ""}</Label>
                {card.completed && <ReactTimeAgo className="ml-2" date={new Date(card.completedDate + "Z")} />}
                {!card.completed && card.dueDate && <>
                    <Label className="ml-2">Due</Label>
                    <ReactTimeAgo className="ml-2" date={new Date(card.dueDate + "Z")} />
                </>}
            </div>
        </div>
    );
}
