import React from "react";
import { useNavigate } from "react-router-dom";
import { FaXmark } from "react-icons/fa6";
import { FaUser, FaUserPlus } from "react-icons/fa";
import { eventEmitter, useUsersContext } from "../../../contexts";
import DetailGrid, { DetailContentArea, DetailControl, DetailControls } from "../DetailGrid";

const UserInvite = () => {
    const { inviteToOrg } = useUsersContext();
    const [email, setEmail] = React.useState("");
    const navigate = useNavigate();

    const vanish = () => {
        navigate(-1);
    };

    const inviteUserToOrg = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(email)) {
            eventEmitter.emit("ERROR", "Invalid email address.");
            return;
        }

        inviteToOrg(email);
        vanish();
    }

    const modalRef = React.createRef<HTMLDivElement>();

    return (<div ref={modalRef} className="card-detail" >
        <div className="card-detail__top-bar">
            <div className="card__icon">
                <FaUser />
            </div>
            <div className="flex-grow" />
            <div className="card-detail__header-container">
                <h2 className="card-header">Invite Team Member</h2>
            </div>
            <div className="flex-grow" />
            <div className="card-detail__close ml-2" onClick={vanish}>
                <FaXmark />
            </div>
        </div>
        <DetailGrid narrow={true}>
            <DetailControls>
                <DetailControl
                    icon={<FaUserPlus />}
                    text="Invite"
                    onClick={inviteUserToOrg}
                />
            </DetailControls>
            <DetailContentArea className="p-4">
                <div className="onboarding__form">
                    <label id="nameLabel" className="onboarding__label">Email</label>
                    <input type="email" id="name" autoFocus className="onboarding__input" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </DetailContentArea>
        </DetailGrid>
    </div>);
};
export default UserInvite;
