import { memo, useRef, useState, useEffect } from "react";
import { cache } from "../../../contexts";
import { UserData } from "../../../contexts/User";
import { FaUser, FaTools } from "react-icons/fa";
import { ListChildComponentProps, areEqual } from "react-window";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";

interface UserCardProps {
    handleClick: () => void;
    card: UserData;
    setDragging: (dragging: UserData | null) => void;
    isActiveDrag: boolean;
    style: React.CSSProperties
    dropRule: (card: UserData) => (item: unknown, monitor: DropTargetMonitor) => void;
}

function UserCard({ handleClick, card, setDragging, isActiveDrag, style, dropRule }: UserCardProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [imageId, setImageId] = useState<string>("");

    useEffect(() => {
        cache.getPortrait(card.id).then((portrait) => {
            setImageId(portrait);
        });
    }, [card]);

    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: "GRID_ITEM",
        item: card,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            };
        },
    });

    useEffect(() => {
        if (!isActiveDrag && isDragging) {
            setDragging(card);
        } else if (isActiveDrag && !isDragging) {
            setDragging(null);
        }
    }, [isActiveDrag, card, setDragging, isDragging]);

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop: dropRule(card),
    });
    connectDrag(ref);
    connectDrop(ref);


    return (
        <div
            ref={ref}
            onClick={handleClick}
            className="card"
            style={style}
            data-handler-id={handlerId}
        >
            <div className="card__top-bar">
                <div className="card__icon">
                    <FaUser />
                </div>
                <div className="card-header">{card.username}</div>
                <div
                    className={
                        card.jobId ? "card__icon--active" : "card__icon--inactive"
                    }
                >
                    <FaTools />
                </div>
            </div>
            <div className="flex justify-between items-center">
                <img src={imageId} className="card__img" />
                <span className="card-text">{card.jobTitle}</span>
            </div>
        </div>
    );
}

const MiniUser = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, dragging, handleClick, setDragging, dropRule } = data;
    const task = items[index];
    if (!task) {
        return null;
    }

    const height = (style.height as number) - 16;

    const isActiveDrag = !!dragging && dragging.id === task.id;
    const opacity = isActiveDrag ? 0 : 1;

    const dynamicStyle = {
        ...style,
        opacity,
        margin: 8,
        marginLeft: 16,
        height,
        width: "calc(100% - 32px)",
    };

    return (<UserCard
        key={`task-${task.id}`}
        handleClick={() => handleClick(task.id)}
        card={task}
        isActiveDrag={isActiveDrag}
        setDragging={(card: UserData | null) => setDragging(card as UserData | null)}
        style={dynamicStyle}
        dropRule={dropRule}
    />);
}, areEqual);

export default MiniUser;
