import { useState } from "react";
import { UserData, orgRoleString } from "../../../contexts/User";
import { useAppContext, useUsersContext, cache } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import DetailCard from "../DetailCard";
import { Label } from "../../utils/Label";
import { useEffect } from "react";

interface UserDetailProps {
    id: string;
}

function UserDetail({ id }: UserDetailProps) {
    const { users } = useUsersContext();
    const { loggedInUser } = useAppContext();
    const user: undefined | UserData = users.find((item) => item.id == id);
    const [userImage, setUserImage] = useState<string | undefined>(user?.imageId);
    const isLoggedInUser = loggedInUser?.id === user?.id;
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            if (user?.imageId) {
                setUserImage(user?.imageId);
            }
            cache.getPortrait(user.id).then((imageId) => { setUserImage(imageId) });
        }
    }, [user]);

    const vanish = () => {
        navigate(-1);
    };

    const orgRole = orgRoleString(user?.orgRole);

    return (
        <DetailCard
            title={user?.username}
            titleIcon={<FaUser />}
            onClose={vanish}
        >
            <div>
                {isLoggedInUser ? <div className="m-4 flex flex-col items-center justify-between rounded-lg border p-4">
                    <div className="image-upload__portrait items-center justify-between ">
                        <img src={userImage} className="image-upload__img" />
                    </div>
                    <div className="items-center grid grid-cols-4">
                        <div /><Label>Email:</Label><span>{user?.email}</span><div />
                        <div /><Label>Role:</Label><span>{loggedInUser?.role}</span><div />
                        <div /><Label>Organization Role:</Label><span>{orgRole}</span><div />
                        <div /><Label>Organization:</Label><span>{loggedInUser?.org?.name}</span><div />
                    </div>
                </div > : <div className="m-4 flex flex-col items-center justify-between rounded-lg border p-4">
                    <div className="image-upload__portrait items-center justify-between ">
                        <img src={userImage} className="image-upload__img" />
                    </div>
                    <div className="items-center grid grid-cols-4">
                        <div /><Label>Email:</Label><span>{user?.email}</span><div />
                    </div>
                </div>}
            </div >
        </DetailCard >
    );
}
export default UserDetail;
