import {
    FaBookOpen,
    FaBook,
} from "react-icons/fa";

interface TaskIconProps {
    taskState: string;
}

function TaskIcon({ taskState }: TaskIconProps) {
    if (taskState == "closed") {
        return (<div className="card__icon">
            <FaBook />
        </div>);
    } else {
        return (<div className="card__icon">
            <FaBookOpen />
        </div>);
    }
}

export default TaskIcon;
