import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App.tsx";
import "./styles/index.css";
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)
ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <div id="wipe-effect"/>
        <App />
    </React.StrictMode>,
);
