import { createRef, useEffect, useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { Popover, PopoverContent, PopoverTrigger } from '../utils/Popover';
import { FaEdit } from 'react-icons/fa';
import { Label } from '../utils/Label';
import { Input } from '../utils/Input';
import { Button } from '../utils/Button';
import Floater from '../Floater';

interface DetailCardProps {
    children?: React.ReactNode;
    title: string | undefined;
    titleIcon: JSX.Element;
    editable?: boolean;
    onSetTitle?: (title: string) => void;
    setRule?: (text: string) => string;
    onClose?: () => void;
}

const DetailCard = ({ children, title, titleIcon, editable, onSetTitle, setRule, onClose }: DetailCardProps) => {
    const [titleText, setTitleText] = useState(title || "");
    const [popoverOpen, setPopoverOpen] = useState(false);

    useEffect(() => {
        setTitleText(title || "");
        setPopoverOpen(false);
    }, [title]);

    const popOverRef = createRef<HTMLButtonElement>();
    const onChangeTitle = () => {
        if (onSetTitle) {
            onSetTitle(titleText);
        }
        setPopoverOpen(false);
    };
    const rule = setRule ? setRule : (text: string) => text;

    return (<div className="card-detail" >
        <div className="card-detail__top-bar">
            {titleIcon}
            <div className="flex-grow" />
            <label className="card-detail__header">{title}</label>
            {editable && <Floater content="Edit title">
                <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                    <PopoverTrigger ref={popOverRef} onClick={() => setPopoverOpen(!popoverOpen)}>
                        <div className="ml-2 card__icon">
                            <FaEdit className="mt-1" size={16} />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="bg-neutral-50 dark:bg-neutral-900">
                        <div className="grid grid-cols-4 gap-2 items-center">
                            <Label>Name</Label>
                            <Input className="col-span-3" value={titleText} onChange={(e) => setTitleText(rule(e.target.value))} />
                            <Button className="btn mt-2 col-span-4" onClick={onChangeTitle}>Save</Button>
                        </div>
                    </PopoverContent>
                </Popover>
            </Floater>}
            <div className="flex-grow" />
            {onClose && <div className="card-detail__close" onClick={onClose}>
                <FaXmark />
            </div>}
        </div>
        {children}
    </div>);
}

export default DetailCard;
