import React from 'react';
import { FaXmark } from 'react-icons/fa6';
import DetailGrid, {
    DetailContentArea,
} from "../DetailGrid";
import { useNavigate } from 'react-router-dom';
import { useMilestoneContext } from '../../../contexts';
import { MilestoneIcon } from 'lucide-react';
import { Input } from '../../utils/Input';
import { Textarea } from '../../utils/Textarea';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../utils/Select';
import { DatePickerWithRange, Timeline } from '../../utils/DatePickerWithRange';

function DataCreate() {
    const { createMilestone } = useMilestoneContext();
    const navigate = useNavigate();
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    const [selectedType, setSelectedType_] = React.useState<string | { "timeline": Timeline } | { "deadline": string } | null>(null);
    const setSelectedType = (value: string) => {
        const interpretedValue = JSON.parse(value as string);
        setSelectedType_(interpretedValue);
    }
    const timeline: Timeline | null = selectedType && typeof (selectedType) === "object" && "timeline" in selectedType ? selectedType.timeline : null;
    const [dateRange, setDateRange] = React.useState<[Date | null, Date | null] | null>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        const elements = form.elements;
        const name = (elements[0] as HTMLInputElement).value as string;
        const description = (elements[1] as HTMLTextAreaElement).value as string;
        const milestoneType = selectedType;
        const startDate = dateRange && dateRange[0] ? dateRange[0].toISOString() : undefined;
        let dueDate = dateRange && dateRange[1] ? dateRange[1].toISOString() : undefined;
        if (startDate === dueDate) {
            dueDate = undefined;
        }

        createMilestone({ name, description, milestoneType, dueDate, startDate });
    };

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <MilestoneIcon size={24} />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create Milestone</h2>
                </div>
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <h2>Milestone details</h2>
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <Input className="m-auto mt-4 w-1/2" type="text" placeholder="Name" />
                        <Textarea className="m-auto mt-4 w-1/2 min-h-32" placeholder="Description" />
                        <Select onValueChange={setSelectedType}>
                            <SelectTrigger className="m-auto mt-4 w-1/2">
                                <SelectValue placeholder="Select a type..." className='bg-white'></SelectValue>
                            </SelectTrigger>
                            <SelectContent className="panel">
                                <SelectGroup>
                                    <SelectLabel className="font-bold dark:bg-neutral-800">Timeline</SelectLabel>
                                    <SelectItem className='select__hover' value='{"timeline": "week"}'>End of Week</SelectItem>
                                    <SelectItem className='select__hover' value='{"timeline": "sprint"}'>Sprint</SelectItem>
                                    <SelectItem className='select__hover' value='{"timeline": "month"}'>End of Month</SelectItem>
                                    <SelectItem className='select__hover' value='{"timeline": "quarter"}'>End of Quarter</SelectItem>
                                    <SelectItem className='select__hover' value='{"timeline": "year"}'>End of Year</SelectItem>
                                </SelectGroup>
                                <SelectGroup>
                                    <SelectLabel className="font-bold dark:bg-neutral-800">General</SelectLabel>
                                    <SelectItem className='select__hover' value='"checkpoint"'>Checkpoint</SelectItem>
                                    <SelectItem className='select__hover' value='"epic"'>Epic</SelectItem>
                                    <SelectItem className='select__hover' value='"launch"'>Launch</SelectItem>
                                    <SelectItem className='select__hover' value='"review"'>Review</SelectItem>
                                    <SelectItem className='select__hover' value='"version"'>Version</SelectItem>
                                </SelectGroup>
                                <SelectGroup>
                                    <SelectLabel className='font-bold dark:bg-neutral-800'>Deadline</SelectLabel>
                                    <SelectItem className='select__hover' value='{"deadline": "commitment"}'>Commitment</SelectItem>
                                    <SelectItem className='select__hover' value='{"deadline": "contract"}'>Contract</SelectItem>
                                    <SelectItem className='select__hover' value='{"deadline": "deliverable"}'>Deliverable</SelectItem>
                                    <SelectItem className='select__hover' value='{"deadline": "event"}'>Event</SelectItem>
                                    <SelectItem className='select__hover' value='{"deadline": "presentation"}'>Presentation</SelectItem> <SelectItem className='dark:bg-neutral-900 hover:bg-neutral-600' value='{"deadline": "production"}'>Manufacturing</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <DatePickerWithRange
                            className="items-center m-auto w-1/2 mt-4"
                            timeline={timeline}
                            onRangeChange={(range) => { setDateRange([range.from || null, range.to || null]) }} />
                        <button type="submit" className="m-auto mt-4 w-1/2 max-w-72 btn">Create Milestone</button>
                    </form >
                </DetailContentArea>
            </DetailGrid>
        </div>);
}
export default DataCreate;
