import { FaDatabase, FaPencilAlt, FaProjectDiagram, FaRegChartBar, FaTasks, FaUsers } from "react-icons/fa";
import {
    FaCircleChevronRight,
    FaCircleCheck,
    FaCircleXmark,
    FaDiagramProject,
} from "react-icons/fa6";
import { cache, useAppContext, useInstructionContext, useTasksContext } from "../contexts";
import AppPage from "./AppPage";
import Spinner from "./Spinner";
import React, { useEffect, useState, useImperativeHandle, useCallback } from "react";

import assistantImage from "../assets/architect.webp";
import errorImage from "../assets/error.webp";
import defaultUserImage from "../assets/protag.webp";
import { ConsentRequest, Message, Consent, ConsentTask, ConsentProject, ConsentTaskUpdateWrapper, Tip, TipPayload } from "../contexts/InstructionContext";
import { Label } from "./utils/Label";
import { Button } from "./utils/Button";
import { useLocation } from "react-router-dom";
import { Lightbulb, Milestone, Terminal } from "lucide-react";
import { TbReport } from "react-icons/tb";

interface MessageDisplay {
    portrait: string;
    text: string;
}

function ErrorMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <img src={portrait} className="personelle__img" />
            <div className="instructions__left-text ml-0 bg-white text-red-500">{text}</div>
        </div>
    );
}

function LeftMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <img src={portrait} className="personelle__img--flipped" />
            <div className="instructions__left-text">{text}</div>
        </div>
    );
}

function RightMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <div className="instructions__right-text">{text}</div>
            <img src={portrait} className="personelle__img" />
        </div>
    );
}

interface TaskConsentPanelProps {
    task: ConsentTask;
    onAccept(content: string): void;
    onReject(): void;
}

function TaskConsentPanel({
    task,
    onAccept,
    onReject,
}: TaskConsentPanelProps) {
    const acceptString = `Created ${task.title}`;
    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{task.title}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm">{task.description}</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface TaskUpdatePanelProps {
    update: ConsentTaskUpdateWrapper;
    onAccept(content: string): void;
    onReject(): void;
}

function TaskUpdatePanel({
    update,
    onAccept,
    onReject,
}: TaskUpdatePanelProps) {
    const { tasks } = useTasksContext();
    const task = tasks.find((t) => t.id === update.task.id);
    const name = task ? task.title : update.task.id;
    const acceptString = `Updated ${name}`;

    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{name}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm">{update.task.description}</div>
                <div className="detail-grid__text text-sm">Update this task?</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface ProjectConsentPanelProps {
    project: ConsentProject;
    onAccept(content: string): void;
    onReject(): void;
}

interface AcceptRejectButtonsProps {
    onAccept(): void;
    onReject(): void;
    acceptString?: string;
    rejectString?: string;
}

function AcceptRejectButtons({ onAccept, onReject, acceptString, rejectString }: AcceptRejectButtonsProps) {
    const acceptText = acceptString || "Accept";
    const rejectText = rejectString || "Reject";
    return <div className="flex mt-2">
        <Button
            className="p-2 transition-colors duration-500 btn"
            onClick={onAccept}
        >
            <FaCircleCheck className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{acceptText}</Label>
        </Button>
        <Button className="rounded-3xl transition-colors duration-500 bg-red-500 hover:bg-netural-red-700 dark:bg-hellotrope-400 dark:hover:bg-neutral-100 text-white dark:text-black ml-auto" onClick={onReject}>
            <FaCircleXmark className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{rejectText}</Label>
        </Button>
    </div>;
}

function ProjectConsentPanel({
    project,
    onAccept,
    onReject,
}: ProjectConsentPanelProps) {
    const acceptString = `Created ${project.title}`;
    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full">
                    <div className="card__icon">
                        <FaDiagramProject />
                    </div>
                    <h2 className="card-header">{project.title.toUpperCase()}</h2>
                </div>
                <div className="detail-grid__text text-sm">{project.description}</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface ConsentMessageProps {
    payload: ConsentRequest;
    onAccept(content: string): void;
    onReject(): void;
}

function TipJar({ visible, tips }: { visible: boolean, tips: Tip[] }) {
    if (!visible) {
        return null;
    }
    return (
        <div className="m-4 w-96 grid grid-cols-2 gap-4">
            {tips.map((tip) => (
                <TipMessage key={tip.tipId} message={tip.payload} />
            ))}
        </div>
    );
}

function TipMessage({ message }: { message: TipPayload }) {
    let icon = <Lightbulb className="mb-2" />;
    if (message.icon === "pen") {
        icon = <FaPencilAlt className="mb-2" />;
    } else if (message.icon === "plan") {
        icon = <FaRegChartBar className="mb-2" />;
    } else if (message.icon === "milestone") {
        icon = <Milestone className="mb-2" />;
    } else if (message.icon === "data") {
        icon = <FaDatabase className="mb-2" />;
    } else if (message.icon === "reports") {
        icon = <TbReport className="mb-2" />;
    } else if (message.icon === "team") {
        icon = <FaUsers className="mb-2" />;
    } else if (message.icon === "projects") {
        icon = <FaProjectDiagram className="mb-2" />;
    } else if (message.icon === "tasks") {
        icon = <FaTasks className="mb-2" />;
    } else if (message.icon === "console") {
        icon = <Terminal className="mb-2" />;
    }

    return <div className="instructions__tip">
        {icon}
        {message.content}
    </div>;
}

function ConsentMessage({ payload, onAccept, onReject }: ConsentMessageProps) {
    if ("task" in payload) {
        return <TaskConsentPanel
            task={payload.task}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("taskUpdate" in payload) {
        return <TaskUpdatePanel
            update={payload.taskUpdate}
            onAccept={onAccept}
            onReject={onReject}
        />
    } else if ("project" in payload) {
        return <ProjectConsentPanel
            project={payload.project}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else {
        return null;
    }
}

interface InstructionMessageProps {
    message: Message;
    onAccept(content: string): void;
    onReject(): void;
}

function InstructionMessage({
    message,
    onAccept,
    onReject,
}: InstructionMessageProps) {
    const [userImage, setUserImage] = useState(defaultUserImage);
    const { loggedInUser } = useAppContext();

    useEffect(() => {
        if (loggedInUser === null) {
            return;
        }
        cache.getPortrait(loggedInUser.id).then((portrait) => {
            setUserImage(portrait);
        });
    }, [loggedInUser]);

    if ("consentId" in message) {
        return <ConsentMessage
            payload={message.payload}
            onAccept={onAccept}
            onReject={onReject} />;
    } else if ("tipId" in message) {
        return null;
    } else if ("content" in message) {
        if ("text" in message.content) {
            const text = message.content.text;
            if (message.role === "error") {
                return <ErrorMessage portrait={errorImage} text={text} />;
            } else if (message.role === "assistant") {
                return <LeftMessage portrait={assistantImage} text={text} />;
            } else if (message.role === "user") {
                return <RightMessage portrait={userImage} text={text} />;
            } else {
                return null;
            }
        } else {
            // TODO
            const obj = JSON.stringify(message.content.object);
            return <LeftMessage portrait={assistantImage} text={obj} />;
        }
    }
}


function instructionsRunning(messages: Message[]): boolean {
    if (messages.length === 0) {
        return false;
    }
    const lastMessage = messages[messages.length - 1];
    if ("role" in lastMessage) {
        return lastMessage.state === "ongoing";
    }
    return false;
}

interface MessagesProps {
    messages: Message[];
    showTips: boolean;
    onAccept(index: number, content: string): void;
    onReject(index: number): void;
    onAcceptAll(): void;
    onRejectAll(): void;
}

interface ConsentIndexGroup {
    message: Consent;
    index: number;
}

const InstructionMessages = React.forwardRef((props: MessagesProps, ref: React.Ref<HTMLDivElement>) => {
    const isRunning = instructionsRunning(props.messages);
    const tips = props.messages.filter((msg) => "tipId" in msg).map((msg) => msg as Tip);

    const consentMessages: ConsentIndexGroup[] = props.messages
        .map((message, index) => { return { message: message as Consent, index }; })
        .filter(({ message }) => "consentId" in message);

    return (
        <div ref={ref} className="instructions__messages">
            {props.messages.map((message, index) => (
                <InstructionMessage
                    key={index}
                    message={message}
                    onAccept={(msg) => props.onAccept(index, msg)}
                    onReject={() => props.onReject(index)}
                />
            ))}

            {consentMessages.length > 1 && <><div className='h-4' /><AcceptRejectButtons onAccept={props.onAcceptAll} onReject={props.onRejectAll} acceptString="Accept All" rejectString="Reject All" /></>}
            <TipJar visible={props.showTips} tips={tips} />
            {isRunning && <Spinner className="text-cerulean-600 dark:text-aquamarine-400" />}
        </div>
    );
});

interface InstructionInputBoxHandle {
    clearText(): void;
}

interface InputProps {
    onSend(msg: string): void;
}

const InstructionInputBox = React.forwardRef<InstructionInputBoxHandle, InputProps>(({ onSend }, ref) => {
    const [content, setContent] = useState("");

    useImperativeHandle(ref, () => ({
        clearText() {
            setContent("");
        },
    }));

    function clickEventHandler() {
        onSend(content);
        setContent("");
    }

    function changeEventHandler(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target !== null) {
            const change = event.target.value;
            setContent(change);
        }
    }

    function handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key == "Enter") {
            if (event.shiftKey) {
                const cursorPosition = event.currentTarget.selectionStart;
                if (cursorPosition !== null) {
                    const newText =
                        content.slice(0, cursorPosition) +
                        "\n" +
                        content.slice(cursorPosition);
                    setContent(newText);
                } else {
                    const newText = content + "\n";
                    setContent(newText);
                }
            } else {
                clickEventHandler();
            }
        }
    }

    return (
        <div className="instructions__input-box flex">
            <input
                onChange={changeEventHandler}
                onKeyDown={handleEnter}
                value={content}
                autoFocus
                type="text"
                className="instructions__input"
                placeholder="Conversation..."
            ></input>
            <div onClick={clickEventHandler} className="mr-1">
                <FaCircleChevronRight />
            </div>
        </div>
    );
});

export function InstructionsPage() {
    return <AppPage active="instructions">
        <Instructions />
    </AppPage>;
}

function isElementInContainerViewport(element: HTMLElement, container: HTMLElement) {
    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    return (
        elementRect.top >= containerRect.top &&
        elementRect.bottom <= containerRect.bottom
    );
}

function Instructions() {
    const location = useLocation();

    const { sendWebMessage, socketIsConnected } = useAppContext();
    const {
        messages,
        conversationOpen,
        onAcceptConsent,
        onRejectConsent,
        onAcceptAllConsent,
        onRejectAllConsent } = useInstructionContext();
    const [showTips, setShowTips] = useState(true);
    const modalRef = React.createRef<HTMLDivElement>();
    const messageRef = React.createRef<HTMLDivElement>();

    const scrollToBottom = useCallback(() => {
        console.log('scroll to bottom');
        if (messageRef.current !== null && modalRef.current !== null) {
            if (isElementInContainerViewport(messageRef.current, modalRef.current)) {
                console.log('scroll to bottom true');
                messageRef.current.scrollTop = messageRef.current.scrollHeight;
            }
        }
    }, [messageRef, modalRef]);

    function sendTextMessage(msg: string) {
        if (!socketIsConnected) {
            console.error("Websocket is not connected");
            return;
        }
        setShowTips(false);
        sendWebMessage({ instruct: { message: { msg: msg } } });
        scrollToBottom();
        inputRef.current?.clearText();
    }

    const inputRef = React.createRef<InstructionInputBoxHandle>();
    const onPage = location.pathname === "/app/chat";
    const openClass = onPage ? "w-full p-4" : "open";
    const isOpen = onPage || conversationOpen;

    return (
        <div
            ref={modalRef}
            className={`instructions__container ${isOpen ? openClass : ""}`}
        >
            {isOpen && <div className="instructions">
                <InstructionMessages
                    ref={messageRef}
                    messages={messages}
                    showTips={showTips}
                    onAccept={onAcceptConsent}
                    onReject={onRejectConsent}
                    onAcceptAll={onAcceptAllConsent}
                    onRejectAll={onRejectAllConsent}
                />
                <InstructionInputBox onSend={sendTextMessage} ref={inputRef} />
            </div>}
        </div>
    );
}
export default Instructions;
