import { useEffect, useState } from "react";
import { apiFetch, isDevelopment } from "../../../contexts";
import Comment, { CommentData } from "./Comment";
import { updateTask } from "../../../contexts/Task";

async function fetchComments(taskId: string): Promise<CommentData[]> {
    if (isDevelopment) {
        return [{
            id: "1",
            author: {
                id: "1",
                created: "2024-01-01T00:00:00",
                username: "FILIP",
                email: "filip@subseq.io",
                jobTitle: "Frontend Engineer",
            },
            content: "Yes, this is the best thing ever",
            createdAt: "2024-01-01T00:00:00",
            updatedAt: "2024-01-01T00:00:00"
        },
        {
            id: "2",
            author: {
                id: "2",
                created: "2024-01-01T00:00:00",
                username: "COCO",
                email: "coco@subseq.io",
                jobTitle: "Backend Engineer",
            },
            content: "But it could be even better",
            createdAt: "2024-01-20T00:00:00",
            updatedAt: "2024-01-30T00:00:00"
        },
        {
            id: "3",
            author: {
                id: "2",
                created: "2024-01-01T00:00:00",
                username: "COCO",
                email: "coco@subseq.io",
                jobTitle: "Backend Engineer",
            },
            content: "Together we can make it even better",
            createdAt: "2024-01-20T00:00:00",
            updatedAt: "2024-01-30T00:00:00"
        }
        ]
    }

    return await apiFetch(`/task/${taskId}/comments`, { method: "GET", });
}

interface TaskCommentsProps {
    taskId: string
}

function TaskComments({ taskId }: TaskCommentsProps) {
    const [comments, setComments] = useState<CommentData[]>([]);
    const [content, setContent] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        fetchComments(taskId).then((comments) => setComments(comments));
    }, [taskId]);

    function changeCommentText(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target !== null) {
            const change = event.target.value;
            setContent(change);
        }
    }

    function clearComment() {
        setIsEditing(false);
        setContent("");
    }

    function submitComment() {
        if (content) {
            updateTask(taskId, { addComment: { content } }).then(() =>
                fetchComments(taskId).then((comments) => setComments(comments))
            );
        }
        setContent("");
    }

    function editComment(commentId: string, content: string) {
        updateTask(taskId, { editComment: { commentId, content } }).then(() =>
            fetchComments(taskId).then((comments) => setComments(comments))
        );
    }

    function deleteComment(commentId: string) {
        updateTask(taskId, { removeComment: { commentId } }).then(() =>
            fetchComments(taskId).then((comment) => setComments(comment))
        );
    }

    return (
        <div id="taskComments" className="card-detail__comments">
            <h2 className="card-detail__comments-header">Comments</h2>
            <div className="card-detail__comments-add">
                {isEditing ? <textarea autoFocus className="card-detail__comment--editing" value={content} onChange={(e) => setContent(e.target.value)} /> : (
                    <div className="instructions__input-box dark:bg-neutral-800">
                        <input
                            onChange={changeCommentText}
                            onFocus={() => setIsEditing(true)}
                            type="text"
                            className="instructions__input"
                            placeholder="Add a comment"
                        ></input>
                    </div>)}
                {isEditing && <div className="flex">
                    <div onClick={clearComment} className="card-detail__button ml-auto mr-2">
                        Cancel
                    </div>
                    <div onClick={submitComment} className="card-detail__button">
                        Comment
                    </div>
                </div>}
            </div>
            {comments.map((comment) => (
                <Comment key={comment.id} comment={comment} editComment={editComment} deleteComment={deleteComment} />
            ))}
        </div>);
}

export default TaskComments;
