import { UserData } from "./User";
import defaultImage from "../assets/unknown.jpg";
import React, { createContext, useCallback, useState, useEffect, useMemo } from 'react';
import { eventEmitter, apiFetch, isDevelopment, useProjectsContext } from ".";
import { getProjectMeta } from "./Project";
import { ProviderProps } from "./ContextTypes";

import beEng from "../assets/fe-eng-light.webp";
import feEng from "../assets/fe-eng.webp";
import feMan from "../assets/fe-manager.webp";

export type Option = { id: number; label: string; value: string; };

export interface TagComponent {
    component: string;
}

export interface TagLabel {
    label: string;
}
export type Tag = TagComponent | TagLabel;

export interface Milestone {
    milestoneType: string;
    name: string;
    description: string;
    dueDate?: string;
    created: string;
    completed: boolean;
    completedDate?: string;
}

export interface TaskData {
    id: string;
    slug: string;
    created: string;
    title: string;
    description: string;
    author: UserData;
    assignee?: UserData;
    abstractState: string;
    priority: number;
    dueDate?: string;
    milestone?: Milestone;
}

function testTasks(): TaskData[] {
    const tasks = [
        {
            id: "21e06126-ac0f-4698-ba01-acc4d0cb9c2e",
            slug: "WELCOME-0",
            created: "2024-01-01T00:00:00",
            title: "A task with a very long description",
            description: `# Use Environment Variables To Pass Secrets To Your Application
As discussed earlier, use environment variables to distinguish between development and production environments.
[Google](https://www.google.com)

## Separate Test Code into Modules
Place your test code or development-only code in separate modules/files.

### Conditionally Import Test Modules
Use dynamic imports with environment checks. This makes sure that the test code is only imported and used in the development build.
         `,
            author: {
                id: "ef991a7e-9c1f-4fae-922d-1bf73d2e563d",
                created: "2024-01-01T00:00:00",
                username: "CARMACK",
                email: "carmack@subseq.io",
                imageId: defaultImage,
                jobTitle: "Software Architect",
            },
            assignee: {
                id: "7e2fd3e6-f6a7-4261-84ed-b31cfdbe9804",
                created: "2024-01-01T00:00:00",
                username: "COCO",
                email: "coco@subseq.io",
                imageId: beEng,
                jobTitle: "Backend Engineer",
            },
            abstractState: 'closed',
            priority: 1,
        },
        {
            id: "dca4e0e4-81b8-4a09-8500-0ec8e8db694e",
            slug: "WELCOME-1",
            created: "2024-01-01T00:00:00",
            title: "Create a great UI",
            description: "Vite + React + TSX -> All the juice",
            author: {
                id: "181215e4-4b2b-4820-b057-a7a827c59fba",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager",
            },
            assignee: {
                id: "b73a3ff6-335f-4527-ba4e-9d55e5cee261",
                created: "2024-01-01T00:00:00",
                username: "FILIP",
                email: "filip@subseq.io",
                imageId: feEng,
                jobTitle: "Frontend Engineer",
            },
            abstractState: 'inProgress',
            dueDate: "2024-07-01T12:00:00Z",
            milestone: {
                milestoneType: "deadline: commitment",
                name: "Promise",
                description: "Promise to Gabe",
                dueDate: "2024-07-01T12:00:00Z",
                created: "2024-04-01T12:00:00Z",
                completed: false,
            },
            priority: 0,
        },
        {
            id: "3db04396-93f6-42de-ae77-814572788ea5",
            slug: "WELCOME-2",
            created: "2024-01-01T00:00:00",
            title: "Populate more tasks",
            description: "We need a lot of tasks for testing our system",
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager",
            },
            assignee: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            abstractState: 'inProgress',
            priority: -1,
        },
        {
            id: "4709d787-dcac-42ca-a3c0-750be8a5159a",
            slug: "WELCOME-3",
            created: "2024-01-01T00:00:00",
            title: "Implement Responsive Navbar",
            description: "Develop a responsive navigation bar component using React and CSS Modules. Ensure compatibility with mobile and desktop views and integrate dropdown menus for complex navigation structures.",
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            abstractState: 'open',
            priority: 0,
        },
        {
            id: "90b6440b-251b-4485-b6c4-6f5a976cffeb",
            slug: "WELCOME-4",
            created: "2024-01-01T00:00:00",
            title: "Optimize Re-render Performance",
            description: "Analyze and optimize the re-rendering process of the React application to enhance performance. Utilize React.memo and useCallback hooks to prevent unnecessary re-renders and improve overall responsiveness.",
            abstractState: 'open',
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            priority: 0,
        },
        {
            id: "837a1fba-29e2-430f-b276-43d3b9ef3c17",
            slug: "WELCOME-5",
            created: "2024-01-01T00:00:00",
            title: "Integrate Redux for State Management",
            description: "Set up Redux for centralized state management. Define actions, reducers, and store to manage the state of user sessions and API data efficiently across the entire application.",
            abstractState: 'open',
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            assignee: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            priority: -1,
        },
        {
            id: "c73242ad-a145-4e7e-9319-f96c065adf98",
            slug: "WELCOME-6",
            created: "2024-01-01T00:00:00",
            title: "Develop Custom Hooks for Data Fetching",
            description: "Create custom React hooks that handle API calls and data fetching logic. Ensure that the hooks are reusable and support error handling and loading states.",
            abstractState: 'open',
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
            priority: 0,
        },
        {
            id: "587b84a7-0bd0-4092-83b2-fc6fc64f8121",
            slug: "WELCOME-7",
            created: "2024-01-01T00:00:00",
            title: "Implement Dark Mode Toggle",
            description: "Implement a toggle switch to enable dark mode across the entire React application. Use Context API to manage the state and ensure UI consistency under both light and dark modes.",
            abstractState: 'open',
            priority: 0,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "2c188bb0-1865-4342-ade7-6a579bdc0e59",
            slug: "WELCOME-8",
            created: "2024-01-01T00:00:00",
            title: "Refactor Class Components to Functional",
            description: "Refactor existing class components into functional components using React Hooks. Focus on maintaining functionality and improving readability and maintainability of the code.",
            abstractState: 'open',
            priority: 0,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "b631b02a-f33c-44ad-b7cd-9c3a3f93115b",
            slug: "WELCOME-9",
            created: "2024-01-01T00:00:00",
            title: "Create Form Validation Library",
            description: "Develop a custom form validation library tailored for React applications. The library should support asynchronous validation and integrate seamlessly with controlled components.",
            abstractState: 'open',
            priority: 0,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "df211d28-4e36-4429-9c26-a8c1a9bc246a",
            slug: "WELCOME-10",
            created: "2024-01-01T00:00:00",
            title: "Setup Automated Testing Environment",
            description: "Set up an automated testing environment using Jest and React Testing Library. Write unit and integration tests for existing components to ensure robustness and prevent regressions.",
            abstractState: 'open',
            priority: 0,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "ed32f1d5-2577-4a24-ba91-60a9f108385f",
            slug: "WELCOME-11",
            created: "2024-01-01T00:00:00",
            title: "Design Dynamic Table Component",
            description: "Create a dynamic table component capable of handling sorting, pagination, and filtering. Ensure that it can dynamically adapt to different datasets and maintain performance.",
            abstractState: 'open',
            priority: 0,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "7918d3f6-9704-45e1-8df1-148be9d2e22d",
            slug: "WELCOME-12",
            created: "2024-01-01T00:00:00",
            title: "Enhance Accessibility Features",
            description: "Audit the application for accessibility issues and implement improvements. Focus on keyboard navigability, screen reader support, and semantic HTML in React components.",
            abstractState: 'open',
            priority: 10,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "345f8b61-1ba6-4645-a9e1-e019260a3e86",
            slug: "WELCOME-13",
            created: "2024-01-01T00:00:00",
            title: "Develop Real-time Data Visualization Tool",
            description: "Build a real-time data visualization tool using React and a suitable charting library (e.g., D3.js). Ensure the tool can handle large volumes of data and update dynamically.",
            abstractState: 'open',
            priority: 10,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "80ed1b00-dcbb-4e0d-b5de-0bd8e6a9e05b",
            slug: "WELCOME-14",
            created: "2024-01-01T00:00:00",
            title: "Implement End-to-End Encryption",
            description: "Implement end-to-end encryption for sensitive data communications in the React application, using WebCrypto API and secure key management practices.",
            abstractState: 'open',
            priority: 10,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "2cd65054-c17a-4dd9-b3f9-546939a75ea3",
            slug: "WELCOME-15",
            created: "2024-01-01T00:00:00",
            title: "Build a Progressive Web App (PWA)",
            description: "Convert the existing React application into a Progressive Web App (PWA), ensuring it is fully functional offline and meets all PWA criteria.",
            abstractState: 'open',
            priority: 20,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "fccc525a-4ac1-4271-9c6e-f1145bdc783a",
            slug: "WELCOME-16",
            created: "2024-01-01T00:00:00",
            title: "Integrate GraphQL API",
            description: "Integrate a GraphQL API to replace existing REST API calls in the application, ensuring more efficient data fetching and reduced bandwidth usage.",
            abstractState: 'open',
            priority: 20,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        },
        {
            id: "900dbacb-9cdd-4699-ba0f-d6ee395c0b8c",
            slug: "WELCOME-17",
            created: "2024-01-01T00:00:00",
            title: "Implement Server-Side Rendering (SSR)",
            description: "Implement server-side rendering for the React application to improve initial load time and enhance SEO performance.",
            abstractState: 'open',
            priority: 20,
            author: {
                id: "4",
                created: "2024-01-01T00:00:00",
                username: "GARRETT",
                email: "garrett@subseq.io",
                imageId: feMan,
                jobTitle: "Frontend Manager"
            },
        }
    ];

    return tasks;
}

export const TASKS_PER_PAGE = 50;
type Order = "alphabetical" | "created" | "due" | "priority";

export async function searchTasks(searchTerm: string, page: number, order: Order): Promise<{ tasks: TaskData[] }> {
    return await apiFetch("/task/search", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            page,
            pageSize: TASKS_PER_PAGE,
            query: searchTerm,
            order,
        }),
    }, "Failed to search tasks");
}

export type MetadataSource = 'tag';

export type TaskUpdate = { assignOther: { userId: string } } |
    "assignSelf" |
{ changeDescription: { description: string } } |
{ changeTitle: { title: string } } |
{ changePriority: { value: number } } |
{ changeDueDate: { dueDate: string | null } } |
{ changeMilestone: { milestoneId?: string } } |
{ addComment: { content: string, parentId?: string } } |
{ editComment: { commentId: string, content: string } } |
{ removeComment: { commentId: string } } |
{ addRelease: { releaseId: string } } |
{ removeRelease: { releaseId: string } } |
{ link: { taskId: string, linkType: string } } |
    "stopWatchingTask" |
{ metadata: { source: MetadataSource, value: object } } |
{ transition: { nodeId: string } } |
    "close" |
    "restart" |
    "archive" |
    "unassign" |
    "undo" |
{ unlink: { taskId: string } } |
{ removeMetadata: { value: object } } |
    "watchTask";

export async function updateTask(taskId: string, data: TaskUpdate): Promise<TaskData | null> {
    console.log("Update task", data);
    return await apiFetch(`/task/${taskId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }, `Failed to update task ${taskId}`);
}

export async function getTaskBySlug(slug: string): Promise<TaskData | null> {
    const query = { slug };
    try {
        const response = await apiFetch<GetTasksResponse>("/task/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: 1,
                pageSize: 1,
                query,
                order: "priority",
            }),
        });
        return response.tasks[0];
    } catch (error) {
        return null;
    }
}

interface GetTasksResponse {
    tasks: TaskData[];
    total?: number;
}

export async function getTasks(index: number, order: Order, count: boolean): Promise<GetTasksResponse> {
    if (isDevelopment) {
        if (index === 1) {
            return { tasks: testTasks(), total: 17 };
        } else {
            return { tasks: [] };
        }
    }

    const query: { count?: string, project: string } = { project: "active" };
    if (count) {
        query.count = "true";
    }

    try {
        return await apiFetch("/task/query", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page: index,
                pageSize: TASKS_PER_PAGE,
                query,
                order,
            }),
        });
    } catch (error) {
        return { tasks: [] };
    }
}

type SlugTaskMap = {
    [id: string]: TaskData;
}

export interface TasksContextProps {
    tasks: TaskData[];
    count: number;
    indexesById: Record<string, number>;
    slugToTask: SlugTaskMap;
    returnedByQuery: Record<string, string[]>;
    order: Order;
    taskCache: Record<string, number>;

    loadNextPage: () => void;
    hasNextPage: () => boolean;
    addTask: (task: TaskData) => void;
    swapTaskItem: (fromId: string, toId: string) => void;
    updateTaskItem: (task: TaskData) => void;
    closeTask: (task: TaskData) => void;
    archiveTask: (task: TaskData) => void;
    restartTask: (task: TaskData) => void;
    fetchBySearch: (searchTerm: string, page: number) => void;
    setOrder: (order: Order) => void;

    projectTags: Option[];
    projectComponents: Option[];
}

export const TasksContext = createContext<TasksContextProps | undefined>(undefined);

const TasksProvider: React.FC<ProviderProps> = ({ children }) => {
    const { activeProject } = useProjectsContext();
    const [tasks, setTasks_] = useState<TaskData[]>([]);
    const [count, setCount] = useState<number>(51);
    const [slugToTask] = useState<SlugTaskMap>({});
    const [order, setOrder] = useState<Order>("priority");
    const [taskCache,] = useState<Record<string, number>>({});

    const [returnedByQuery,] = useState<Record<string, string[]>>({});

    const indexesById = useMemo(() => {
        const indexesById: Record<string, number> = {};
        for (let i = 0; i < tasks.length; i += 1) {
            indexesById[tasks[i].id] = i;
        }
        return indexesById;
    }, [tasks]);

    const [projectTags, setProjectTags] = useState<Option[]>([]);
    const [projectComponents, setProjectComponents] = useState<Option[]>([]);

    useEffect(() => {
        if (activeProject?.id) {
            getProjectMeta(activeProject.id).then((metadata: object[]) => {
                const tagsParsed: Tag[] = metadata.filter((meta) => {
                    return "label" in meta || "component" in meta;
                }) as Tag[];
                const tagElements: Option[] = tagsParsed
                    .filter((tag: Tag) => "label" in tag)
                    .map((tag, id) => {
                        const label = (tag as TagLabel).label;
                        return { id, label, value: label };
                    });
                setProjectTags(tagElements);
                const componentElements: Option[] = tagsParsed
                    .filter((tag) => "component" in tag)
                    .map((tag, id) => {
                        const label = (tag as TagComponent).component;
                        return { id, label, value: label };
                    });
                setProjectComponents(componentElements);
            });
        }
    }, [activeProject?.id]);

    const setTasks = useCallback((newTasks: TaskData[]) => {
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
            slugToTask[task.slug] = task;
            taskCache[task.id] = Date.now();
        }
        setTasks_([...newTasks]);
    }, [slugToTask, taskCache]);

    const [page, setPage] = useState(1);

    const loadNextPage = useCallback(() => {
        getTasks(page, order, false).then((response) => {
            if (response.tasks.length) {
                const newTasks = response.tasks.filter((task: TaskData) => {
                    return !(task.id in indexesById);
                });
                setTasks([...tasks, ...newTasks]);
            }
        });
    }, [page, tasks, setTasks, order, indexesById]);

    const hasNextPage = useCallback(() => {
        return tasks.length < count;
    }, [tasks, count]);

    useEffect(() => {
        const fetchTasks = async () => {
            setPage(1);
            const { tasks, total } = await getTasks(1, order, true);
            if (total) {
                setCount(total);
            }
            setTasks(tasks);
        };
        fetchTasks();
    }, [activeProject?.id, setTasks, order]);

    const updateTaskItem = useCallback((task: TaskData) => {
        console.log("Updating task", task.id);
        const newTasks = tasks.map(item => {
            if (item.id === task.id) {
                return task;
            }
            return item;
        });
        taskCache[task.id] = Date.now();
        slugToTask[task.slug] = task;
        setTasks_(newTasks);
    }, [tasks, slugToTask, taskCache]);

    const addTask = useCallback((task: TaskData) => {
        console.log("Add task", task.id);
        if (task.id in indexesById) {
            updateTaskItem(task);
        } else {
            const newTasks = [task, ...tasks];
            setTasks(newTasks);
        }
    }, [tasks, setTasks, indexesById, updateTaskItem]);

    useEffect(() => {
        const addTaskEvent = ({ task }: { task: TaskData }) => {
            addTask(task);
        };

        eventEmitter.on("TASK-ADD", addTaskEvent);
        return () => {
            eventEmitter.off("TASK-ADD", addTaskEvent);
        };
    }, [addTask]);

    const closeTask = useCallback((task: TaskData) => {
        updateTask(task.id, "close").then((newTaskItem) => {
            console.log("Closed task", task.id);
            if (newTaskItem) {
                updateTaskItem(newTaskItem);
            } else {
                task.abstractState = 'closed';
            }
        });
    }, [updateTaskItem]);

    const archiveTask = useCallback((task: TaskData) => {
        updateTask(task.id, "archive").then(() => {
            console.log("Archived task", task.id);
            const newTasks = tasks.filter((item) => item.id !== task.id)
            setTasks(newTasks);
        });
    }, [tasks, setTasks]);

    const restartTask = useCallback((task: TaskData) => {
        updateTask(task.id, "restart").then((newTaskItem) => {
            console.log("Restarted task", task.id);
            if (newTaskItem) {
                updateTaskItem(newTaskItem);
            } else {
                task.abstractState = 'closed';
            }
        });
    }, [updateTaskItem]);

    const swapTaskItem = useCallback((fromId: string, toId: string) => {
        const cardIndex: number = indexesById[fromId];
        const afterIndex: number = indexesById[toId];
        const card = tasks[cardIndex];
        const newItems = [...tasks];
        newItems.splice(cardIndex, 1);
        newItems.splice(afterIndex, 0, card);
        setTasks_(newItems);
    }, [tasks, indexesById]);

    const fetchBySearch = useCallback((searchTerm: string, page: number) => {
        searchTasks(searchTerm, page, order).then((response) => {
            const taskIds = response.tasks.map((task: TaskData) => task.id);
            if (searchTerm in returnedByQuery) {
                returnedByQuery[searchTerm] = [...returnedByQuery[searchTerm], ...taskIds];
            } else {
                returnedByQuery[searchTerm] = taskIds;
            }
            const newTasks = response.tasks.filter((task: TaskData) => {
                return !(task.id in indexesById);
            });
            setTasks([...tasks, ...newTasks]);
        });
    }, [indexesById, tasks, setTasks, returnedByQuery, order]);

    const value = useMemo(() => {
        return {
            addTask,
            archiveTask,
            closeTask,
            taskCache,
            count,
            fetchBySearch,
            hasNextPage,
            indexesById,
            loadNextPage,
            order,
            projectComponents,
            projectTags,
            restartTask,
            returnedByQuery,
            setOrder,
            slugToTask,
            swapTaskItem,
            tasks,
            updateTaskItem,
        };
    }, [addTask,
        archiveTask,
        closeTask,
        taskCache,
        count,
        fetchBySearch,
        hasNextPage,
        indexesById,
        loadNextPage,
        order,
        projectComponents,
        projectTags,
        restartTask,
        returnedByQuery,
        setOrder,
        slugToTask,
        swapTaskItem,
        tasks,
        updateTaskItem]
    );

    return (
        <TasksContext.Provider value={value}>
            {children}
        </TasksContext.Provider>
    );
};
export default TasksProvider;
