import { useState, useCallback, useEffect } from "react";
import { TaskData } from "../../contexts/Task";

interface ItemFilterContext<Card> {
    setItems: (value: Card[]) => void;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    filteredItems: Card[];
}

interface ItemFilterProps<Card> {
    filter: (searchTerm: string, items: Card[]) => Card[];
}

export function useItemFilter<Card>({ filter }: ItemFilterProps<Card>): ItemFilterContext<Card> {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredItems, setFilteredItems] = useState<Card[]>([]);
    const [items, _setItems] = useState<Card[]>([]);

    const setItems = useCallback((value: Card[]) => {
        _setItems(value);
        setFilteredItems(filter(searchTerm, value));
    }, [filter, searchTerm]);

    useEffect(() => {
        setFilteredItems(filter(searchTerm, items));
    }, [searchTerm, items, filter]);

    return { setItems, searchTerm, setSearchTerm, filteredItems };
}

export function filterTasksBySubstrings(tasks: TaskData[], input: string): TaskData[] {
    const substrings = input.split(/\s+/).filter(Boolean);  // Split input into substrings and remove empty ones
    return tasks.filter(task => {
        const title = task.title.toLowerCase();
        const description = task.description.toLowerCase();
        const slug = task.slug.toLowerCase();

        return substrings.every(substring => {
            const lower = substring.toLowerCase();
            return title.includes(lower) || description.includes(lower) || slug.includes(lower);
        });
    });
}
