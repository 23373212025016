import React, { useState } from "react";
import { eventEmitter } from "../contexts";

function SearchBar() {
    const [searchString, setSearchString] = useState<string>("");

    function handleSearchUpdate(e: React.ChangeEvent<HTMLInputElement>) {
        const term = e.target.value;
        setSearchString(term);
        eventEmitter.emit("SEARCH-FILTER", term);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Escape") {
            setSearchString("");
            eventEmitter.emit("SEARCH-FILTER", "");
        }
        if (e.key === "Enter") {
            e.preventDefault();
            e.currentTarget.blur();
        }
    }

    return (
        <input
            type="text"
            className="omnibar__input"
            value={searchString}
            onChange={handleSearchUpdate}
            onKeyDown={handleKeyDown}
            placeholder="Search..."
        ></input>
    );
}
export default SearchBar;
