import { useAppContext } from '../contexts';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const { loggedInUser } = useAppContext();
    const navigate = useNavigate();
    if (loggedInUser?.role !== 'admin') {
        navigate('/');
    }

    return (
        <div>
            <h1>Admin</h1>
            <p>Admin page</p>
        </div>
    );
}
export default Admin;
