import { createRef, useCallback, useEffect, useState } from 'react';
import { Card } from './MiniMilestone';
import InfiniteLoader from 'react-window-infinite-loader';
import AppPage from '../../AppPage';
import { useAppContext, useMilestoneContext } from '../../../contexts';
import UtilityCard from "../UtilityCard.tsx";
import { FixedSizeList } from 'react-window';
import { FaPlus } from 'react-icons/fa';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MilestoneDetailsView from './MilestoneDetailsView.tsx';

const BOTTOM_MARGIN = 148;

const MilestoneGrid = () => {
    const { width, height } = useAppContext();
    const { milestones, hasNextPage, loadNextPage } = useMilestoneContext();
    const navigate = useNavigate();

    const handleUtilClick = useCallback(() => {
        navigate("/app/milestones/create");
    }, [navigate]);

    const [utilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText] = useState("Milestone Details");

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < milestones.length;
    }, [milestones.length]);

    const outerDivRef = createRef<HTMLDivElement>();

    const [contentHeight, setHeight] = useState(height);
    const [contentWidth, setWidth] = useState(width);

    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setHeight(rect.height - BOTTOM_MARGIN);
            setWidth(rect.width);
        }
    }, [outerDivRef, width, height]);

    const itemData = {
        items: milestones,
        handleClick: (id: string) => navigate(`/app/milestones/${id}`),
    };

    return <div className="flex-col max-w-lg" ref={outerDivRef}>
        <h1 className="text-2xl font-bold ml-4">Milestones</h1>
        <UtilityCard
            handleCardClick={handleUtilClick}
            icon={utilIcon}
            style={{ width: "calc(100% - 32px)" }}
            cardText={utilText}
        />
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={hasNextPage ? milestones.length + 1 : milestones.length}
            loadMoreItems={loadNextPage}
        >
            {({ onItemsRendered, ref }) => (
                <FixedSizeList
                    height={contentHeight}
                    width={contentWidth}
                    className='scroll-list'
                    itemSize={160}
                    itemData={itemData}
                    itemCount={milestones.length}
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {Card}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    </div>;

}

const MilestoneDetailsRoute = () => {
    const { narrow } = useAppContext();
    return (<>
        {!narrow && <MilestoneGrid />}
        <MilestoneDetailsView />
    </>);
}

const MilestonePage: React.FC = () => {
    return <AppPage active="data">
        <Routes>
            <Route path="/" element={<MilestoneGrid />} />
            <Route path=":id" element={<MilestoneDetailsRoute />} />
        </Routes>
    </AppPage>;
};
export default MilestonePage;
