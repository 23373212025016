/** @type {import('tailwindcss').Config} */
export default {
  mode: "jit",
  darkMode: 'selector',
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      fontSize: {
        sm: ['12px', '20px'],
        base: ['16px', '24px'],
        lg: ['20px', '28px'],
        xl: ['24px', '32px'],
      },
      colors: {
        cerulean: {
          300: "#8AB8D0",
          600: "#4281A4",
          800: "#295166"
        },
        verdigris: {
          300: "#8DCECC",
          500: "#48A9A6",
          700: "#37817E"
        },
        vanilla: {
          100: "#FBF8DA",
          300: "#F5EE9E"
        },
        rufous: {
          400: "#EA340B",
          600: "#A72608"
        },
        spacecadet: {
          700: "#21295C"
        },
        ultraviolet: {
          400: "#6046AA",
          600: "#54428E"
        },
        hellotrope: {
          400: "#E879F9"
        },
        night: {
          600: "#404040",
          900: "#151515"
        },
        aquamarine: {
          400: "#7AE7C7"
        },
        vermillion: {
          700: "#F24333"
        }
      }
    }
  },
  plugins: [],
};
