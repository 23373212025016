import DataDetail from "./DataDetail";
import DataCreate from "./DataCreate";
import { useParams } from "react-router-dom";

function DataDetailsView() {
    const { id } = useParams();
    if (id === "create") {
        return <DataCreate />;
    } else if (id) {
        return <DataDetail id={id} />;
    } else {
        return null;
    }
}

export default DataDetailsView;
