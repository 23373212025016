import { useParams } from 'react-router-dom';
import ProjectCreate from './ProjectCreate';
import ProjectDetail from "./ProjectDetail";

function ProjectDetailsView() {
  const { id } = useParams();
  if (id === 'create') {
    return <ProjectCreate />;
  } else if (id) {
    return <ProjectDetail id={id} />;
  } else {
    return null;
  }
}
export default ProjectDetailsView;
