import { useState, useCallback, useEffect } from "react";
import { eventEmitter, useProjectsContext } from "../../../contexts";
import { useItemFilter } from "../CardGrid";
import { CardData, isProjectData } from "../CardDataInterfaces";
import UtilityCard from "../UtilityCard";
import { FaArchive, FaPlus } from "react-icons/fa";
import { Project } from "../../../contexts/Project";
import { Card } from "./MiniProject";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";

const BOTTOM_MARGIN = 148;

interface ProjectGridProps {
    projects: Project[];
    containerWidth: number;
    containerHeight: number;
    projectSource: string;
}

function ProjectGrid({ projects, containerWidth, containerHeight, projectSource }: ProjectGridProps) {
    const {
        addProject,
        archiveProject,
        updateProject
    } = useProjectsContext();
    const navigate = useNavigate();

    const filter = useCallback((searchTerm: string, items: Project[]): Project[] => {
        let newFilteredItems = items;
        if (searchTerm !== "") {
            newFilteredItems = newFilteredItems.filter((item: Project) => {
                if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
        return newFilteredItems;
    }, []);

    const { setItems, setSearchTerm, filteredItems } = useItemFilter<Project>({ filter });
    useEffect(() => { setItems(projects); }, [projects, setItems]);

    useEffect(() => {
        eventEmitter.on("SEARCH-FILTER", setSearchTerm);
        return () => {
            eventEmitter.off("SEARCH-FILTER", setSearchTerm);
        };
    }, [addProject, updateProject, setSearchTerm]);

    const handleUtilDrop = useCallback((card: CardData) => {
        if (isProjectData(card)) {
            archiveProject(card.id);
        }
    }, [archiveProject]);

    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const createText = "Create Project";
    const [utilText, setUtilText] = useState(createText);
    const [dragging, setDragging] = useState<Project | null>(null);
    const location = useLocation();

    useEffect(() => {
        if (dragging) {
            setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
            setUtilText("Archive Project");
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText(createText);
        }
    }, [dragging]);

    const handleClick = useCallback((id: string) => {
        const project = projects.find((item) => item.id == id);

        const maybeId = location.pathname.split("/").pop();
        if (project && project.id !== maybeId) {
            navigate(project.id);
        }
    }, [projects, navigate, location]);

    const height = containerHeight - BOTTOM_MARGIN;
    const width = containerWidth;

    const handleUtilClick = useCallback(() => {
        navigate(`/app/projects/create?projectSource=${projectSource}`);
    }, [navigate, projectSource]);

    const itemData = {
        items: filteredItems,
        dragging,
        handleClick,
        setDragging,
        dropRule: () => { }
    };

    const children = (
        <div className="flex-col">
            <UtilityCard
                handleCardClick={handleUtilClick}
                handleCardDrop={handleUtilDrop}
                dragging={dragging}
                icon={utilIcon}
                cardText={utilText}
                style={{ height: 90, width: "calc(100% - 32px)" }}
            />
            <InfiniteLoader
                isItemLoaded={() => true}
                itemCount={filteredItems.length}
                loadMoreItems={() => { }}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        className="scroll-list"
                        height={height}
                        itemSize={100}
                        width={width}
                        itemData={itemData}
                        itemCount={filteredItems.length}
                        onItemsRendered={onItemsRendered}
                        ref={ref} >
                        {Card}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        </div>
    );
    return children;
}
export default ProjectGrid;
