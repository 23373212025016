import { createContext, useEffect, useState, useCallback } from 'react';
import { ProviderProps } from "./ContextTypes";
import { UserData } from "./User";
import axios from "axios";
import { apiFetch, eventEmitter } from '.';

export interface Transcript {
    id: string;
    createdAt: string;
    createdBy: UserData;
    title: string;
    body?: string;
    metadata: unknown;
}

async function getTranscript(id: string): Promise<Transcript> {
    return await apiFetch(`/transcript/${id}`, { method: "GET" });
}

async function getTranscripts(page: number): Promise<Transcript[]> {
    return await apiFetch(`/transcript/list/${page}`, { method: "GET" });
}

async function addTranscript(form: FormData): Promise<Transcript> {
    return await axios.post('/transcript', form, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

async function addTranscriptFiles(targetFiles: FileList, meta?: object) {
    const addedTranscripts = [];
    for (const file of targetFiles) {
        const title = file.name;
        const formData = new FormData();
        formData.append(title, file)
        const metadata = {
            ...meta,
            lastModified: file.lastModified,
            type: file.type,
            size: file.size
        };
        formData.append("metadata", JSON.stringify(metadata));
        try {
            const transcript = await addTranscript(formData);
            addedTranscripts.push(transcript);
        } catch (err) {
            eventEmitter.emit("ERROR", `Could not upload transcript ${title}: ${err}`);
            break;
        }
    }
    return addedTranscripts;
}

export interface DataContextProps {
    transcripts: Transcript[],
    hasNextPage: boolean,
    loadNextPage: () => void,
    clearTranscriptCache: () => void,
    getTranscript: (transcriptId: string) => Promise<Transcript>,
    addTranscripts: (targetFiles: FileList) => void,
}
export const DataContext = createContext<DataContextProps | undefined>(undefined);

const fetchTranscripts = async (setHasNextPage: (value: boolean) => void,
    setTranscripts: (value: Transcript[]) => void,
    setPage: (value: number) => void,
    transcripts: Transcript[],
    page: number) => {
    const newTranscripts = await getTranscripts(page);
    if (newTranscripts.length === 0) {
        setHasNextPage(false);
    } else {
        setTranscripts([...transcripts, ...newTranscripts]);
        setPage(page + 1);
    }
};

const DataProvider: React.FC<ProviderProps> = ({ children }) => {
    const [transcripts, setTranscripts] = useState<Transcript[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [dataCache, setDataCache] = useState(Date.now());

    const loadNextPage = useCallback(() => {
        fetchTranscripts(setHasNextPage, setTranscripts, setPage, transcripts, page);
    }, [page, transcripts]);

    useEffect(() => {
        setPage(1);
        setTranscripts([]);
        setHasNextPage(true);
        fetchTranscripts(setHasNextPage, setTranscripts, setPage, [], 1);
    }, [dataCache]);

    const addTranscripts = useCallback((files: FileList, meta?: object) => {
        addTranscriptFiles(files, meta).then((newTranscripts) => setTranscripts([...transcripts, ...newTranscripts]));
    }, [transcripts]);

    const value = {
        transcripts,
        hasNextPage,
        loadNextPage,
        clearTranscriptCache: () => setDataCache(Date.now()),
        getTranscript,
        addTranscripts,
    };

    return (<DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>);
}
export default DataProvider;
