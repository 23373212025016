import React from 'react';

export interface ProviderProps {
    children: React.ReactNode;
}

export const LOGGED_OUT = "loggedOutError";

export function checkLoggedOut(response: Response) {
    if (response.status == 401) {
        throw LOGGED_OUT;
    }
}

export async function getJsonOrLogout<T>(response: Response): Promise<T> {
    if (!response.ok && response.status == 401) {
        throw LOGGED_OUT;
    }
    return await response.json();
}
