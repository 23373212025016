import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiFetch, isDevelopment } from '../contexts';
import AppPage from './AppPage';
import Spinner from './Spinner';

interface Status {
    status: string;
    customerEmail: string;
}

async function fetchStatus(sessionId: string): Promise<Status> {
    return await apiFetch(`/org/status?session_id=${sessionId}`, {
        method: 'GET',
    });
}

function PurchasePage() {
    const navigate = useNavigate();
    const [status, setStatus] = useState<Status | null>(null);
    const [searchParams] = useSearchParams();

    const loadStatus = useCallback((sessionId: string) => {
        fetchStatus(sessionId).then((status) => {
            setStatus(status);
            if (status.status === 'open') {
                setTimeout(() => {
                    loadStatus(sessionId);
                }, 1000);
            }
        });
    }, []);

    useEffect(() => {
        if (isDevelopment) {
            setStatus({ status: "done", customerEmail: "test@example.com" })
            return;
        }

        const sessionId = searchParams.get('session_id');
        if (sessionId) {
            loadStatus(sessionId);
        }
    }, [searchParams, loadStatus]);

    if (!status || status.status === 'open') {
        return <div className='text-white'><Spinner /></div>;
    }

    if (status.status === 'expired') {
        navigate('/app/checkout');
    }

    return (
        <AppPage active="purchase">
            <div className="description__container">
                <h1>Thank you for upgrading!</h1>
                <br />
                <p>An email receipt will be sent to {status.customerEmail}</p>
                <p>Now that you're a customer, you'll have access to our growing list of features.</p>
                <br />
                <p>By upgrading now you're helping us build the future of our product. We're excited to have you on board!</p>
                <button className="btn btn-primary mt-4 p-4" onClick={() => navigate('/app/tasks')}>Keep going!</button>
            </div>
        </AppPage>
    );
}

export default PurchasePage;
