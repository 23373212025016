import React, { memo, useEffect, useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import TaskIcon from "./TaskIcon";
import { PriorityIcon } from "./Priority";
import { CardData } from "../CardDataInterfaces";
import { TaskData } from "../../../contexts/Task";
import { ListChildComponentProps, areEqual } from "react-window";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, dragging, handleClick, setDragging, dropRule, hasTaskIcon } = data;
    const task = items[index];
    if (!task) {
        return null;
    }

    const height = style.height as number;
    const isActiveDrag = !!dragging && dragging.id === task.id;
    const opacity = isActiveDrag ? 0 : 1;

    const dynamicStyle = {
        ...style,
        opacity,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniTask
        key={`task-${task.id}`}
        handleClick={() => handleClick(task.id)}
        card={task}
        isActiveDrag={isActiveDrag}
        setDragging={(card: CardData | null) => setDragging(card as TaskData | null)}
        style={dynamicStyle}
        dropRule={dropRule}
        hasTaskIcon={hasTaskIcon}
    />);
}, areEqual);

interface MiniTaskProps {
    handleClick: () => void;
    card: TaskData;
    setDragging: (dragging: CardData | null) => void;
    isActiveDrag: boolean;
    style: React.CSSProperties
    dropRule: (card: TaskData) => (item: unknown, monitor: DropTargetMonitor) => void;
    hasTaskIcon?: boolean;
}

function MiniTask({ handleClick, card, setDragging, isActiveDrag, style, dropRule, hasTaskIcon = true }: MiniTaskProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: "GRID_ITEM",
        item: card,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            };
        },
    });

    useEffect(() => {
        if (!isActiveDrag && isDragging) {
            setDragging(card);
        } else if (isActiveDrag && !isDragging) {
            setDragging(null);
        }
    }, [isActiveDrag, card, setDragging, isDragging]);


    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop: dropRule(card),
    });
    connectDrag(ref);
    connectDrop(ref);

    const taskState = card.abstractState;
    return (
        <div
            ref={ref}
            onClick={handleClick}
            className="card"
            style={style}
            data-handler-id={handlerId}
        >
            <div className="card__top-bar">
                <div className="card__icon">
                    <PriorityIcon value={card.priority} />
                </div>
                <div className="card-header">{card.title}</div>
                {hasTaskIcon && <TaskIcon taskState={taskState} />}
            </div>
            <h2 className="ml-2 text-left font-bold">{card.slug}</h2>
            <span className="m-2">{card.description}</span>
        </div>
    );
}
export default MiniTask;
