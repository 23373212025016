import MilestoneDetail from "./MilestoneDetail";
import MilestoneCreate from "./MilestoneCreate";
import { useParams } from "react-router-dom";

function MilestoneDetailsView() {
    const { id } = useParams();
    if (id === "create") {
        return <MilestoneCreate />;
    } else if (id) {
        return <MilestoneDetail id={id} />;
    } else {
        return null;
    }
}

export default MilestoneDetailsView;
