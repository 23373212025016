import AppPage from "../../AppPage";
import { Route, Routes } from "react-router-dom";
import UserGrid from "./UserGrid";
import UserDetailsView from "./UserDetailsView";
import { useAppContext } from "../../../contexts";
import { createRef, useEffect, useState } from "react";

function UserGridView() {
    const { width, height } = useAppContext();
    const outerDivRef = createRef<HTMLDivElement>();
    const [containerWidth, setContainerWidth] = useState(width);
    const [containerHeight, setContainerHeight] = useState(height);

    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height);
        }
    }, [outerDivRef, width, height]);

    return <div className="w-full max-w-lg" ref={outerDivRef}>
        <h1 className="ml-4">Users</h1>
        <UserGrid containerWidth={containerWidth} containerHeight={containerHeight} />
    </div>;
}

function UserDetailRoute() {
    const { narrow } = useAppContext();

    return (<>
        {!narrow && <UserGridView />}
        <UserDetailsView />
    </>);
}

function UserWindow() {
    return (
        <AppPage active="users">
            <Routes>
                <Route path="/" element={<UserGridView />} />
                <Route path=":id" element={<UserDetailRoute />} />
            </Routes>
        </AppPage>
    );
}
export default UserWindow;
