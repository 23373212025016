import { useEffect, useState } from 'react';
import { apiFetch, isDevelopment } from '../contexts';
import { FaCheck } from 'react-icons/fa';
import Spinner from './Spinner';
import { useNavigate } from 'react-router-dom';
import AppPage from './AppPage';

function ListItem({ text }: { text: string }) {
    return (
        <li className="flex w-full justify-center">
            <div className="flex-grow" />
            <FaCheck className="mr-4 text-cerulean-600" />
            <div className="w-44 text-right">{text}</div>
            <div className="flex-grow" />
        </li>
    );
}

interface Plan {
    name: string;
    tagline: string;
    price?: number;
    priceAnnual?: number;
    currency?: string;
    features: string[];
}

interface Pricing {
    starter: Plan;
    pro: Plan;
    enterprise: Plan;
}

function currencyToSymbol(price: number | undefined, currency: string | undefined): string {
    if (!price || !currency) {
        return '';
    }

    switch (currency.toUpperCase()) {
        case 'USD':
            return `$${price.toFixed(0)}`;
        case 'EUR':
            return `${price.toFixed(0)}€`;
        case 'GBP':
            return `£${price.toFixed(0)}`;
        default:
            return `${price} ${currency}`;
    }
}

async function fetchPricing(): Promise<Pricing> {
    return await apiFetch(`/org/pricing`, {
        method: 'GET',
    });
}

function developmentTestData(): Pricing {
    return {
        starter: {
            name: 'Starter plan',
            tagline: 'For individuals and small teams',
            features: [
                'Task management',
                'Basic AI tools',
                'Small team size (3)',
                'Limited projects (3)',
            ],
        },
        pro: {
            name: 'Pro plan',
            tagline: 'For growing teams',
            price: 16,
            priceAnnual: 160,
            currency: 'USD',
            features: [
                'All Basic features',
                'Project planning view',
                'Project architect',
                'Full access to AI tools',
                'Speech to text',
                'Unlimited team size',
                'Unlimited projects',
                'Support contact',
            ],
        },
        enterprise: {
            name: 'Enterprise plan',
            tagline: 'For large teams and organizations',
            features: [
                'All Pro features',
                'Development support',
                'White glove support',
            ],
        },
    };
}

function UpgradePage() {
    const [pricing, setPricing] = useState<Pricing | null>(null);
    const [period, setPeriod] = useState<string>("month");
    const navigate = useNavigate();

    useEffect(() => {
        if (isDevelopment) {
            setPricing(developmentTestData());
            return;
        }

        fetchPricing().then((data) => {
            setPricing(data);
        });
    }, []);

    if (!pricing) {
        return <div className="h-full w-full text-white"><Spinner /></div>;
    }

    const proPrice = period === 'month' ? pricing.pro.price : pricing.pro.priceAnnual;
    const onClickSlider = () => {
        if (period === 'month') {
            setPeriod('year');
        } else {
            setPeriod('month');
        }
    }
    const sliderClass = (active: boolean): string => active ? 'price-grid__slider--selected' : 'flex-grow flex-shrink-0 text-lg';

    return (
        <AppPage active="upgrade">
            <div className="price-grid">
                <div className="price-grid__header">
                    <h1 className='text-4xl'>Upgrade your account</h1>
                    <p className='text-xl'>Maximize your productivity</p>
                    <div className="price-grid__slider_region" onClick={onClickSlider}>
                        <button className={sliderClass(period === "month")}>Monthly</button>
                        <button className={sliderClass(period === "year")}>Annual</button>
                    </div>
                </div>
                <div className="price-grid__left">
                    <div className="flex-grow" />
                    <h2 className='text-4xl m-2'>{pricing.starter.name}</h2>
                    <p className='text-xl m-2'>{pricing.starter.tagline}</p>
                    <ul className="m-10 justify-center object-center">
                        {pricing.starter.features.map((feature, index) => (
                            <ListItem key={index} text={feature} />
                        ))}
                    </ul>
                    <div className="flex-grow" />
                    <p className='text-5xl m-4 font-light'>Free</p>
                </div>
                <div className="price-grid__center">
                    <h2 className='text-4xl m-2'>{pricing.pro.name}</h2>
                    <p className='text-xl m-2'>{pricing.pro.tagline}</p>
                    <div className="flex-grow" />
                    <ul className="m-10">
                        {pricing.pro.features.map((feature, index) => (
                            <ListItem key={index} text={feature} />
                        ))}
                    </ul>
                    {period === 'year' && <h6 className='text-2xl translate-x-28 -rotate-12 bottom-28 absolute text-rufous-400 dark:text-hellotrope-400'>20% off!</h6>}
                    <h2 className='text-6xl font-bold'>{currencyToSymbol(proPrice, pricing.pro.currency)}</h2>
                    <h6 className='txt-xl'>per user per {period}</h6>
                    <button className="btn btn-primary m-4 text-xl" onClick={() => navigate(`/app/checkout?plan=pro_${period}`)}>Upgrade</button>
                </div>
                <div className="price-grid__right">
                    <div className="flex-grow" />
                    <h2 className='text-4xl m-2'>{pricing.enterprise.name}</h2>
                    <p className='text-xl m-2'>{pricing.enterprise.tagline}</p>
                    <ul className="m-10">
                        {pricing.enterprise.features.map((feature, index) => (
                            <ListItem key={index} text={feature} />
                        ))}
                    </ul>
                    <div className="flex-grow" />
                    <a href="https://subseq.io/contact-us"><button className="btn btn-primary m-4 text-xl">Contact us</button></a>
                </div>
            </div>
        </AppPage>
    );
}
export default UpgradePage;
