import NotificationBell from "./NotificationBell";
import { InfoIcon } from "./Floater";
import RecordButton from "./RecordButton";
import SearchBar from "./SearchBar";
import React from "react";

import { useAppContext } from "../contexts";
import { PersonelleImage } from "./Personelle";
import { useNavigate } from "react-router-dom";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "./utils/DropdownMenu";
import { CreditCard, User } from "lucide-react";
import { MdExitToApp } from "react-icons/md";

function UserInfoArea() {
    const { loggedInUser } = useAppContext();
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
    const navigate = useNavigate();

    if (!loggedInUser) {
        return null;
    }

    function handleLogoutClick() {
        window.location.replace("/auth/logout");
    }

    return (
        <div className="omnibar__user-area items-center">
            <NotificationBell />
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <PersonelleImage
                        userData={loggedInUser}
                        onMouseDown={() => setIsSettingsOpen(!isSettingsOpen)}
                        className="personelle__tag--omnibar"
                    />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-52 text-center bg-neutral-50 dark:bg-neutral-950">
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => navigate('/app/settings')} >
                        <User className="mr-2 h-4 w-4" />
                        <span>Settings</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => navigate('/app/upgrade')} >
                        <CreditCard className="mr-2 h-4 w-4" />
                        <span>Upgrade</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => handleLogoutClick()} >
                        <MdExitToApp className="mr-2 h-4 w-4" />
                        <span>Logout</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

function OmniBar() {
    const { loggedInUser } = useAppContext();
    if (!loggedInUser) {
        return null;
    }

    return (
        <div className="omnibar">
            <div id="omniSearch" className="omnibar__input-container flex">
                <InfoIcon className="ml-2 mr-2" content="Full text search" />
                <SearchBar />
                <RecordButton />
            </div>
            <UserInfoArea />
        </div>
    );
}

export default OmniBar;
