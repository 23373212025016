import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../contexts";

function ServerDown() {
    const { socketIsConnected } = useAppContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (socketIsConnected()) {
            navigate('/');
        }
        const timer = setTimeout(() => {
            if (socketIsConnected()) {
                navigate('/');
            }
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [navigate, socketIsConnected]);

    return (<div className="text-center w-full m-auto">
        We'll be back shortly
    </div>);
}
export default ServerDown
