import { apiFetch } from "../contexts";
import { UserData } from "../contexts/User";

interface InviteNotification {
    orgId: string;
    orgName: string;
    invitedBy: UserData;
}

interface TaskChange {
    from: string,
    taskId: string,
    update: string,
}

interface CommentChange {
    from: string,
    taskId: string,
    content: string,
}

interface AssignmentChange {
    from: string,
    taskId: string,
}

type NotificationMessage = InviteNotification |
    { assignment: AssignmentChange } |
    { update: TaskChange } |
    { comment: CommentChange } |
    string;

export interface Notification {
    id: string;
    channel: string;
    message: NotificationMessage;
    read: boolean;
    created: string;
}

export async function hasUnreadNotifications(): Promise<boolean> {
    const res = await apiFetch<boolean>("/notification/unread", {
        method: 'GET',
    });
    return res;
}

export async function markNotificationRead(notificationId: string): Promise<void> {
    await fetch(`/notification/${notificationId}/read`, {
        method: 'POST',
    });
}

export async function markAllNotificationsRead(): Promise<void> {
    await fetch('/notification/read_all', {
        method: 'POST',
    });
}

export async function fetchNotifications(page: number): Promise<Notification[]> {
    return await apiFetch(`/notification/list/${page}`, {
        method: 'GET',
    });
}
