import React, { useCallback, useEffect, useState } from "react";
import { Project, ProjectTrackerType, Repo, ImportUpdate, trackerOptions, ImportState, ImportStateInfo, ProjectKeyType } from "../../../contexts/Project";
import { FaXmark, FaDiagramProject, FaFloppyDisk, FaCirclePlus } from "react-icons/fa6";
import { FaKey, FaEdit } from "react-icons/fa";
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import DetailGrid, {
    DetailContent,
    DetailContentArea,
    DetailControl,
    DetailControls,
    DetailSidePanel,
} from "../DetailGrid";
import { useNavigate } from "react-router-dom";
import { apiFetch, eventEmitter, useProjectsContext } from "../../../contexts";
import Spinner from "../../Spinner";
import { InfoIcon } from "../../Floater";
import DetailCard from "../DetailCard";
import { Popover, PopoverContent, PopoverTrigger } from "../../utils/Popover";
import { Label } from "../../utils/Label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../utils/Select";
import { Input } from "../../utils/Input";
import Checkbox from "../../utils/Checkbox";
import { Button } from "../../utils/Button";
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "../../utils/Dialog";
import { Separator } from "../../utils/Separator";

interface ProjectRepoProps {
    repo: Repo;
    projectId: string;
    onRemove: (repoId: string) => void;
}

async function updateRepo(projectId: string, repoId: string, update: object) {
    return await apiFetch(`/project/${projectId}/import/${repoId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(update)
    });
}

function ProjectRepo({ repo, projectId, onRemove }: ProjectRepoProps) {
    const displayState = (state: ImportState) => {
        if (typeof state === "object" && "inProgress" in state) {
            return state.inProgress;
        } else if (typeof state === "object" && "paused" in state) {
            return "paused";
        }
        return state;
    };

    const displayInfo = (info: ImportStateInfo | undefined) => {
        if (typeof info === "object") {
            if ("issues" in info) {
                return `(${info.issues.issues} issues)`;
            } else if ("comments" in info) {
                return `(${info.comments.total} issues)`;
            } else if ("portraits" in info) {
                return `(${info.portraits.total} users)`;
            }
        }
        return null;
    };
    const info = displayInfo(repo.importState?.info);
    const done = repo.importState && (repo.importState.state === "done" || repo.importState.state === "error");

    const startImport = () => {
        updateRepo(projectId, repo.id, { state: "start" }).then();
    };

    const pauseImport = () => {
        updateRepo(projectId, repo.id, { state: "pause" }).then();
    };

    const onDialogConfirmDelete = () => {
        onRemove(repo.id);
    }

    return <div className="mt-4 p-2 grid grid-gap-2 grid-cols-3">
        <h2 className="col-span-2 font-bold">Repository</h2>
        <Dialog>
            <DialogTrigger>
                <FaXmark className="col-span-1 ml-auto mr-6" />
            </DialogTrigger>
            <DialogContent>
                <Label className="editable__label text-center">Confirm Delete</Label>
                <DialogClose className="flex">
                    <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                    <Button className="editable__button">No</Button>
                </DialogClose>
            </DialogContent>
        </Dialog>

        <div className="flex mr-4 col-span-3">
            <label className="editable__label flex-grow">Tracker</label>
            <label className="editable__text">{repo.tracker}</label>
        </div>
        <div className="flex mr-4 col-span-3">
            <label className="editable__label flex-grow">Origin</label>
            <label className="editable__text">{repo.repoGitRemote}</label>
        </div>
        {repo.repoWebUrl && (<div className="flex text-right col-span-3">
            <a href={repo.repoWebUrl} className="flex-grow text-right" target="_blank" rel="noreferrer">
                <label className="editable__link">Website</label>
            </a>
        </div>)}
        <div className="flex col-span-3 mr-6">
            <label className="editable__label flex-grow">Default</label>
            <input type="checkbox"
                className="editable__text"
                checked={repo.isDefault}
                disabled={true} />
        </div>
        {repo.importState ? <>
            <div className="flex col-span-3 mr-4">
                <label className="editable__label">Importing</label>
                <div className="flex-grow text-right">
                    <label className="editable__text flex-grow">{displayState(repo.importState.state)}</label>
                    {info && <label className="ml-2 editable__text flex-grow">{info}</label>}
                </div>
            </div>
            {!done ? <>
                <Spinner className="col-span-3" />
                <Button className="btn col-span-3" onClick={pauseImport}>Pause</Button>
            </> : repo.importState.error ? <>
                <h6 className="editable__text col-span-3 mr-4 text-rufous-600 dark:text-hellotrope-400">{repo.importState.error}</h6>
                <InfoIcon content="The import will pull in all issues, comments, and users from the repository." />
                <Button className="btn mr-4" onClick={startImport}>Import</Button>
                <div />
            </> : <>
            </>}
        </> : <>
            <InfoIcon content="The import will pull in all issues, comments, and users from the repository." />
            <Button className="btn mr-4" onClick={startImport}>Import</Button>
            <div />
        </>}
        <Separator className="col-span-3 mt-4 mb-4" />
    </div>;
}

interface ProjectPanelProps {
    projectData: Project;
}

function ProjectPanel({ projectData }: ProjectPanelProps) {
    const navigate = useNavigate();
    const [editMode, setEditMode] = useState(false);
    const [description, setDescription] = useState(projectData.description);
    const { setActiveProject, updateProject } = useProjectsContext();

    useEffect(() => {
        setDescription(projectData.description);
    }, [projectData.description]);

    const onChangeDescription = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }, [setDescription]);

    const editToggleSave = useCallback(() => {
        if (editMode) {
            updateProject(projectData.id, { description: description });
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }, [editMode, setEditMode, description, updateProject, projectData.id]);

    const handleActivate = useCallback(() => {
        setActiveProject(projectData);
        navigate('/app/tasks');
    }, [projectData, setActiveProject, navigate]);

    const handleRmRepo = useCallback((repoId: string) => {
        const values = { deleteRepo: { repoId } };
        updateProject(projectData.id, values);
    }, [projectData.id, updateProject]);

    const repos = projectData.repos;

    useEffect(() => {
        const handleImportUpdate = ({ update }: { update: ImportUpdate }) => {
            if (update.projectId === projectData.id) {
                const repo = repos.find((repo) => repo.id === update.projectTrackerId);
                if (repo) {
                    repo.importState = {
                        state: update.state,
                        error: update.error,
                        info: update.info
                    };
                }
            }
        }
        eventEmitter.on('IMPORT-UPDATE', handleImportUpdate);
        return () => {
            eventEmitter.off('IMPORT-UPDATE', handleImportUpdate);
        }
    }, [projectData, repos]);

    const [narrow, setNarrow] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const resizeListener = () => {
            setNarrow(window.innerWidth < 1000);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <h2>Description</h2>
            <DetailContent
                text={description}
                editMode={editMode}
                enableAutoFocus={true}
                onChange={onChangeDescription} />
        </DetailContentArea>
    ) : (
        <DetailContentArea className="flex-col">
            {repos && repos.map((repo, index) => (
                <ProjectRepo
                    key={index}
                    repo={repo}
                    projectId={projectData.id}
                    onRemove={handleRmRepo}
                />
            ))}
            <h2>Description</h2>
            <DetailContent
                text={description}
                editMode={editMode}
                enableAutoFocus={true}
                onChange={onChangeDescription} />
        </DetailContentArea>
    );
    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel>
            <div>
                {repos && repos.map((repo, index) => (
                    <ProjectRepo
                        key={index}
                        repo={repo}
                        projectId={projectData.id}
                        onRemove={handleRmRepo}
                    />
                ))}
            </div>
        </DetailSidePanel>
    ) : <div />;

    return (
        <DetailGrid narrow={narrow}>
            <DetailControls>
                <DetailControl
                    id="openProjectButton"
                    icon={<MdOutlineSwitchAccessShortcut />}
                    text="Open Project"
                    onClick={handleActivate}
                />
                <DetailControl
                    icon={editMode ? <FaFloppyDisk /> : <FaEdit />}
                    text={editMode ? "Save" : "Edit"}
                    onClick={editToggleSave}
                />
                <AddRepositoryControl projectId={projectData.id} />
                <SetTokenControl projectId={projectData.id} />
            </DetailControls>
            {DetailSidePanelMode}
            {DetailContentMode}
        </DetailGrid>
    );
}

function SetTokenControl({ projectId }: { projectId: string }) {
    const { updateProject } = useProjectsContext();
    const [open, setOpen] = useState(false);

    const handleSetTokenFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const key = formData.get("key") as ProjectKeyType | null;
        const token = formData.get("token") as string | null;
        if (!key || !token) {
            return;
        }
        const values = { updateProjectKey: { key, token } };
        console.log("Update project", projectId, values);
        updateProject(projectId, values);
        setOpen(false);
    }, [projectId, updateProject]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <DetailControl icon={<FaKey />} text="Set Token" />
            </PopoverTrigger>
            <PopoverContent className="panel">
                <div className="p-4 grid grid-gap-4">
                    <form onSubmit={handleSetTokenFormSubmit} className="grid grid-cols-3 items-center gap-4">
                        <Select required={true} name="key" defaultValue="github">
                            <SelectTrigger className="bg-neutral-50 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-400 col-span-3">
                                <SelectValue placeholder="Key" />
                            </SelectTrigger>
                            <SelectContent className="bg-neutral-50 dark:bg-neutral-700">
                                <SelectGroup>
                                    <SelectItem className="select__hover" value="github">Github</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <Input required={true} name="token" className="col-span-3" type="text" placeholder="Token" />
                        <Button className="col-span-3 btn" type="submit">Add</Button>
                    </form>
                </div>
            </PopoverContent>
        </Popover>
    );
}

function AddRepositoryControl({ projectId }: { projectId: string }) {
    const { updateProject } = useProjectsContext();
    const [open, setOpen] = useState(false);

    const handleImportFormSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const tracker = formData.get("tracker") as ProjectTrackerType | null;
        const repo = formData.get("repo") as string | null;
        const repoUrl = formData.get("repoUrl") as string | null;
        const isDefault = formData.get("isDefault") === "on";
        if (!tracker || !repo || !repoUrl) {
            return;
        }
        const values = { addRepo: { tracker: tracker, repo, repoUrl, isDefault } };
        console.log("Update project", projectId, values);
        updateProject(projectId, values);
        setOpen(false);
    }, [projectId, updateProject]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <DetailControl icon={<FaCirclePlus />} text="Add Repository" />
            </PopoverTrigger>
            <PopoverContent className="bg-neutral-50 dark:bg-neutral-900">
                <div className="p-4 grid grid-gap-4">
                    <form onSubmit={handleImportFormSubmit} className="grid grid-cols-3 items-center gap-4">
                        <Select required={true} name="tracker">
                            <SelectTrigger className="bg-neutral-50 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-400 col-span-3">
                                <SelectValue placeholder="Tracker" />
                            </SelectTrigger>
                            <SelectContent className="bg-neutral-50 dark:bg-neutral-700">
                                <SelectGroup>
                                    {trackerOptions.map((tracker) => (
                                        <SelectItem className="select__hover" key={tracker.id} value={tracker.value}>
                                            {tracker.label}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <Input required={true} name="repo" className="col-span-3" type="text" placeholder="Git Origin" />
                        <Input required={true} name="repoUrl" className="col-span-3" type="text" placeholder="Web URL" />
                        <Label className="col-span-2">Default</Label>
                        <Checkbox name="isDefault" className="col-span-1 ml-auto" />
                        <Button className="col-span-3 btn" type="submit">Add</Button>
                    </form>
                </div>
            </PopoverContent>
        </Popover>
    );
}

interface ProjectDetailProps {
    id: string
}

const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');
function testUuid(candidate: string): boolean {
    return uuidRegex.test(candidate);
}

function ProjectDetail({ id }: ProjectDetailProps) {
    const { projects, orgProjects, updateProject } = useProjectsContext();

    let project: undefined | Project = undefined;
    if (testUuid(id)) {
        project = orgProjects.find((item) => item.id == id) || projects.find((item) => item.id == id);
    } else {
        project = orgProjects.find((item) => item.name == id) || projects.find((item) => item.name == id);
    }

    const navigate = useNavigate();

    const vanish = () => {
        navigate(-1);
    };

    function onChangeName(name: string) {
        if (project && name != project.name && name) {
            updateProject(project.id, { name: name });
        }
    }

    function toUpper(name: string) {
        return name.toUpperCase();
    }

    return (
        <DetailCard
            title={project?.name}
            titleIcon={<FaDiagramProject />}
            editable={true}
            onSetTitle={onChangeName}
            setRule={toUpper}
            onClose={vanish}>
            {project && <ProjectPanel projectData={project} />}
        </DetailCard>
    );
}

export default ProjectDetail;
