import { useEffect, useState } from "react";
import { useDataContext } from "../../../contexts";
import { Transcript } from "../../../contexts/Data";
import { FaVoicemail } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import DetailGrid, { DetailContentArea } from "../DetailGrid";
import Personelle from "../../Personelle";
import ReactTimeAgo from "react-time-ago";
import DetailCard from "../DetailCard";

interface DataDetailProps {
    id: string
}

function DataDetail({ id }: DataDetailProps) {
    const { getTranscript } = useDataContext();
    const [data, setData] = useState<Transcript | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getTranscript(id).then((transcript) => setData(transcript));
    }, [id, getTranscript]);

    const vanish = () => {
        navigate(-1);
    };

    if (!data) {
        return null;
    }
    const createdDate = new Date(data.createdAt + "Z");

    return (
        <DetailCard
            title={data.title}
            titleIcon={<FaVoicemail />}
            onClose={vanish} >
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <div className="ml-auto mb-2 w-64 mr-2">
                        <Personelle userData={data.createdBy} tag="Uploaded by" />
                        <div className="flex">
                            <div className="flex-grow" />
                            <ReactTimeAgo className="mr-2" date={createdDate} />
                        </div>
                    </div>
                    <p className="detail-grid__text">{data.body}</p>
                </DetailContentArea>
            </DetailGrid>
        </DetailCard>
    );
}
export default DataDetail;
