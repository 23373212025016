import { createRef, useEffect, useState } from "react";

import AppPage from "../../AppPage";
import TaskGrid from "./TaskGrid";
import Swimlanes from "./Swimlanes";
import TaskDetailsView from "./TaskDetailsView";
import { Routes, Route } from 'react-router-dom'
import { TaskData } from "../../../contexts/Task";
import { useAppContext, useProjectsContext, useTasksContext } from "../../../contexts";
import { useCallback } from "react";
import { filterTasksBySubstrings } from "../CardGrid";
import { DropTargetMonitor } from "react-dnd";

function Details() {
    const { mobile } = useAppContext();
    // Matches the styling of the TaskGrid in Swimlanes.tsx
    return (
        <>
            {!mobile && <TaskScrollView />}
            <TaskDetailsView />
        </>
    );
}

function TaskScrollView() {
    const { activeProject } = useProjectsContext();
    const { mobile, width, height } = useAppContext();
    const {
        indexesById,
        returnedByQuery,
        swapTaskItem
    } = useTasksContext();

    const filter = useCallback((searchTerm: string, items: TaskData[]): TaskData[] => {
        let newFilteredItems = items;

        if (searchTerm !== "") {
            const searchFiltered = filterTasksBySubstrings(newFilteredItems, searchTerm);

            // Never filter out items that are returned by the search query
            const extras = returnedByQuery[searchTerm];
            if (extras) {
                // Push items that are not already in searchFiltered
                const searchFilteredIds = new Set(searchFiltered.map((item) => item.id));
                searchFiltered.push(...extras.filter((item) => !searchFilteredIds.has(item))
                    .map((id) => items[indexesById[id]]));
            }
            newFilteredItems = searchFiltered;
        }

        return newFilteredItems;
    }, [indexesById, returnedByQuery]);

    const dropRule = useCallback((card: TaskData) => {
        return (item: unknown, monitor: DropTargetMonitor) => {
            const cardItem = item as TaskData;
            if (monitor.isOver() && cardItem.id !== card.id) {
                swapTaskItem(cardItem.id, card.id);
            }
        };
    }, [swapTaskItem]);
    const [dragging, setDragging] = useState<TaskData | null>(null);

    const outerDivRef = createRef<HTMLDivElement>();
    const [containerWidth, setContainerWidth] = useState(width);
    const [containerHeight, setContainerHeight] = useState(height);

    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height);
        }
    }, [outerDivRef, width, height]);
    const outerDivClasses = mobile ? (
        "ml-1 mt-1 w-full"
    ) : ("ml-2 ml-2 w-1/3");

    return <div className={outerDivClasses} ref={outerDivRef}>
        <h2 className="text-center">Project:&nbsp;{activeProject?.name}</h2>
        <TaskGrid filter={filter} containerWidth={containerWidth} containerHeight={containerHeight} dragging={dragging} setDragging={setDragging} showUtilityCard={true} autoOpenTask={true} dropRule={dropRule} />
    </div>
}

function TaskWindow() {
    const { mobile } = useAppContext();
    return (
        <AppPage active="tasks">
            <Routes>
                <Route path="/" element={mobile ? <TaskScrollView /> : <Swimlanes />} />
                <Route path=":slug" element={<Details />} />
            </Routes>
        </AppPage>
    );
}

export default TaskWindow;
