import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { TaskData, updateTask } from "../../../contexts/Task";
import { filterTasksBySubstrings } from "../CardGrid.tsx";
import { isDevelopment, useAppContext, useTasksContext, useProjectsContext, apiFetch } from "../../../contexts";
import TaskGrid from "./TaskGrid";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { Link } from "react-router-dom";

type Literal = string | number | boolean | null | symbol | bigint;

interface FilterRule {
    field: "assignee" | "abstractState";
    value?: Literal;
}

interface SwimlaneData {
    id: string;
    name: string;
    showUtilityCard: boolean;
    dropBehavior: "unassign" | "assignSelf" | "close";
    filterRules: FilterRule[];
}

interface SwimlanesResult {
    swimlanes: SwimlaneData[];
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
}

async function loadProjectSwimlanes(projectId: string): Promise<SwimlanesResult> {
    return await apiFetch(`/project/${projectId}/swimlanes`, {});
}

interface SwimlaneProps {
    swimlane: SwimlaneData;
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
    width: number;
    height: number;
}

function Swimlane({ swimlane, dragging, setDragging, width, height }: SwimlaneProps) {
    const ref = useRef<HTMLDivElement>(null);
    const {
        indexesById,
        returnedByQuery,
        swapTaskItem,
        updateTaskItem,
    } = useTasksContext();

    const filter = useCallback((searchTerm: string, items: TaskData[]): TaskData[] => {
        let newFilteredItems = items;
        for (const rule of swimlane.filterRules) {
            newFilteredItems = newFilteredItems.filter((item) => {
                if (rule.value === undefined) {
                    return item[rule.field] !== undefined && item[rule.field] !== null;
                } else if (rule.value === null) {
                    return item[rule.field] === null || item[rule.field] === undefined;
                } else {
                    return item[rule.field] === rule.value;
                }
            });
        }

        if (searchTerm !== "") {
            const idsBeforeSearch = new Set(newFilteredItems.map((item) => item.id));
            const searchFiltered = filterTasksBySubstrings(newFilteredItems, searchTerm);

            // Never filter out items that are returned by the search query
            const extras = returnedByQuery[searchTerm];
            if (extras) {
                // Push items that are not already in searchFiltered
                const searchFilteredIds = new Set(searchFiltered.map((item) => item.id));
                searchFiltered.push(...extras.filter((item) => !searchFilteredIds.has(item) && idsBeforeSearch.has(item))
                    .map((id: string) => items[indexesById[id]]));
            }
            newFilteredItems = searchFiltered;
        }

        return newFilteredItems;
    }, [swimlane.filterRules, indexesById, returnedByQuery]);

    const [visibleTasks, setVisibleTasks] = useState<Set<string>>(new Set());

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop(item: unknown, monitor: DropTargetMonitor) {
            const task = item as TaskData;
            if (monitor.isOver()) {
                if (!(task.id in visibleTasks)) {
                    updateTask(task.id, swimlane.dropBehavior).then((task) => {
                        if (task) {
                            updateTaskItem(task);
                        }
                    });
                }
            }
        },
    });
    connectDrop(ref);

    const dropRule = useCallback((card: TaskData) => {
        return (item: unknown, monitor: DropTargetMonitor) => {
            const cardItem = item as TaskData;
            if (monitor.isOver()) {
                if (cardItem.id in visibleTasks) {
                    if (cardItem.id !== card.id) {
                        swapTaskItem(cardItem.id, card.id);
                    }
                } else {
                    updateTask(cardItem.id, swimlane.dropBehavior).then((task) => {
                        if (task) {
                            updateTaskItem(task);
                        }
                    });
                }
            }
        };
    }, [swapTaskItem, visibleTasks, swimlane.dropBehavior, updateTaskItem]);
    const swimlaneName = transformToCamel(swimlane.name) + "Swimlane";

    return (<div id={swimlaneName} ref={ref} className="p-1 m-2 border-neutral-700 dark:border-neutral-200 border-dashed rounded-md border-2">
        <h2 className="text-center">{swimlane.name}</h2>
        <TaskGrid
            containerWidth={width}
            containerHeight={height}
            dragging={dragging}
            setDragging={setDragging}
            filter={filter}
            showUtilityCard={swimlane.showUtilityCard}
            autoOpenTask={false}
            setVisibleTasks={setVisibleTasks}
            dropRule={dropRule}
            hasTaskIcon={false}
        />
    </div>);
}

function transformToCamel(name: string): string {
    let newName = name.replace(/\s/g, "");
    newName = newName.charAt(0).toLowerCase() + newName.slice(1);
    return newName;
}

function Swimlanes() {
    const [swimlanes, setSwimlanes] = useState<SwimlaneData[]>([]);
    const { activeProject } = useProjectsContext();
    const { loggedInUser, width, height, mobile } = useAppContext();

    useEffect(() => {
        if (isDevelopment) {
            setSwimlanes([
                {
                    id: "1",
                    name: "Backlog",
                    showUtilityCard: true,
                    dropBehavior: "unassign",
                    filterRules: [{ field: "abstractState", value: "open" }]
                },
                {
                    id: "2",
                    name: "In Progress",
                    showUtilityCard: false,
                    dropBehavior: "assignSelf",
                    filterRules: [{ field: "abstractState", value: "inProgress" }]
                },
                {
                    id: "3",
                    name: "Closed",
                    showUtilityCard: false,
                    dropBehavior: "close",
                    filterRules: [{ field: "abstractState", value: "closed" }]
                },
            ]);
        }
        if (activeProject?.id === undefined) {
            return
        }
        loadProjectSwimlanes(activeProject.id).then((result: SwimlanesResult) => {
            setSwimlanes(result.swimlanes);
        });
    }, [activeProject?.id]);

    const [dragging, setDragging] = useState<TaskData | null>(null);
    if (swimlanes?.length === 0) {
        if (loggedInUser !== null && activeProject === null) {
            return (<div className="description__container">
                <h1 className="m-10">You need to create a project first!</h1>
                <p>After creating a project you can start adding tasks to it.</p>
                <br />
                <Link to="/app/projects/create?projectSource=organization">Create your first project</Link>
            </div>);
        } else {
            return null;
        }
    }

    const adjustedWidth = mobile? width : width - 128 - 84;
    const adjustedHeight = mobile? height - 112 : height - 80;

    return (
        <Suspense fallback={<h1 className="m-10">Loading...</h1>}>
            {swimlanes.map((swimlane) => (
                <Swimlane dragging={dragging} setDragging={setDragging} key={swimlane.id} swimlane={swimlane} width={adjustedWidth / swimlanes.length} height={adjustedHeight} />
            ))}
        </Suspense>
    );
}
export default Swimlanes;
