import React, { useState, useEffect, useCallback } from "react";

import { CgSmartphoneChip } from "react-icons/cg";
import { FaRobot, FaCodeBranch, FaFloppyDisk } from "react-icons/fa6";
import { FaTasks, FaEdit, FaLink, FaArchive, FaRecycle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { apiFetch, eventEmitter, handleLogout, isDevelopment, useAppContext, useProjectsContext, useTasksContext } from "../../../contexts";
import { filterTasksBySubstrings } from "../CardGrid";
import DetailGrid, {
    DetailContent,
    DetailControls,
    DetailSidePanel,
    DetailControl,
    DetailContentArea,
} from "../DetailGrid";
import DetailCard from "../DetailCard";
import Milestone from "./Milestone";
import Personelle, { PersonelleSelector } from "../../Personelle";
import PlannedTask from "./PlannedTask";
import Priority from "./Priority";
import TaskComments from "./TaskComments";
import TaskState, { TaskExpandedData, LinkState, TagState } from "./TaskState";
import { Button } from "../../utils/Button";
import Floater from "../../Floater";
import { Option, EditDateTime, EditSearch } from "../EditableDropdown";
import { Popover, PopoverContent, PopoverTrigger } from "../../utils/Popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../utils/Select";
import { TaskData, Tag, updateTask, getTaskBySlug, Milestone as MilestoneData } from "../../../contexts/Task";
import { UserData, isOrgPlanner } from "../../../contexts/User";
import { getAssignableUsers, getProjectRepo } from "../../../contexts/Project";

function convertGitUrlToHttps(gitUrl: string): string {
    const regex = /git@github\.com:(.*)\/(.*).git/;
    const match = gitUrl.match(regex);

    if (!match) {
        throw new Error('Invalid GitHub SSH URL');
    }
    const user = match[1];
    const repo = match[2];
    return `https://github.com/${user}/${repo}`;
}

async function replanTask(taskId: string) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return await apiFetch(
        `/task/${taskId}/replan`,
        {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                units: "days",
                today: date,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        },
        "Failed to replan task"
    );
}

async function getTaskDetail(taskId: string): Promise<TaskExpandedData> {
    return await apiFetch(`/task/${taskId}?denormalized=true`, { method: "GET" }, "Failed to get task detail");
}

async function runTask(taskId: string) {
    return await apiFetch(`/task/run/${taskId}`, { method: "PUT" }, (response) => {
        if (!response.ok) {
            response.text().then((text) => {
                eventEmitter.emit("ERROR", "Failed to run task: " + text);
            });
            throw new Error("Failed to run task");
        }
    });
}

function TaskDetail({ slug }: { slug: string }) {
    const { loggedInUser, narrow } = useAppContext();
    const { activeProject } = useProjectsContext();
    const { tasks, slugToTask, taskCache, updateTaskItem, archiveTask, restartTask } = useTasksContext();
    const navigate = useNavigate();

    const taskData = slugToTask[slug as string] || null;
    const [thisTaskCache, setThisTaskCache] = useState<null | number>(null);

    useEffect(() => {
        if (taskData?.id) {
            setThisTaskCache(taskCache[taskData.id]);
        }
    }, [taskData?.id, taskCache]);

    useEffect(() => {
        const updateTaskLocals = (task: TaskData) => {
            setDescription(task.description);
            setMilestone(task.milestone);
        };

        if (taskData) {
            updateTaskLocals(taskData);
        } else {
            getTaskBySlug(slug).then((task) => {
                if (task) {
                    slugToTask[task.slug] = task;
                    updateTaskLocals(task);
                }
            });
        }
    }, [slug, taskData, slugToTask]);

    const [description, setDescription] = useState("No task was found matching that name");
    const [milestone, setMilestone] = useState<undefined | MilestoneData>(undefined);

    const [assignableUsers, setAssignableUsers] = useState<UserData[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [taskDetail, setTaskDetail] = useState<TaskExpandedData>({
        metadata: [],
        linksIn: [],
        dependencies: [],
        watchers: [],
        state: { id: "", nodeName: "" },
        validTransitions: [],
    });

    useEffect(() => {
        if (taskData?.id) {
            getTaskDetail(taskData.id)
                .then((detail) => setTaskDetail(detail))
                .catch(handleLogout(() => console.error("Could not fetch task detail")));
        }
    }, [taskData?.id, taskDetail.state.id, thisTaskCache, taskData?.abstractState]);

    useEffect(() => {
        if (taskData?.id) {
            getAssignableUsers()
                .then((users) => setAssignableUsers(users))
                .catch(handleLogout(() => {
                    if (!isDevelopment) {
                        eventEmitter.emit("ERROR", "Failed to retrieve users");
                    }
                }));
        }
    }, [taskData?.id]);

    useEffect(() => {
        if (taskData) {
            setDescription(taskData.description);
            setMilestone(taskData.milestone);
        }
    }, [taskData, taskData?.description, taskData?.title, taskData?.milestone]);

    async function openCodeLink(event: React.MouseEvent<Element, MouseEvent>) {
        event.stopPropagation();
        const repo = getProjectRepo(activeProject);

        if (repo) {
            const gitUrl = repo.repoWebUrl;
            if (gitUrl) {
                window.open(gitUrl, "_blank");
            } else {
                const gitRemote = repo.repoGitRemote;
                const httpsUrl = convertGitUrlToHttps(gitRemote);
                window.open(httpsUrl, "_blank");
            }
        }
    }

    async function onChangeTitle(title: string) {
        if (taskData && title != taskData.title) {
            const task = await updateTask(taskData.id, { changeTitle: { title } });
            if (task) {
                updateTaskItem(task);
            }
        }
    }

    function assignUser(userId: string) {
        if (taskData) {
            updateTask(taskData.id, { assignOther: { userId } }).then((task) => {
                if (task) {
                    updateTaskItem(task);
                }
            }).catch(handleLogout());
        }
    }

    function handleDescriptionChange(
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) {
        if (event.target !== null) {
            const change = event.target.value;
            setDescription(change);
        }
    }

    function handleSetState(flowNodeId: string, nodeName: string) {
        if (taskData) {
            updateTask(taskData.id, { transition: { nodeId: flowNodeId } })
                .then((newTaskData) => {
                    setTaskDetail({
                        ...taskDetail,
                        state: { id: flowNodeId, nodeName },
                    })
                    if (newTaskData) {
                        updateTaskItem(newTaskData);
                    }
                })
                .catch(handleLogout());
        }
    }

    async function editToggleSave(event: React.MouseEvent<Element, MouseEvent>) {
        if (event.target === null) {
            return;
        }
        if (editMode) {
            // Save
            if (taskData) {
                const update = { changeDescription: { description } };
                const task = await updateTask(taskData.id, update);
                if (task) {
                    updateTaskItem(task);
                }
            }
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }

    const vanish = () => {
        navigate(-1);
    };

    const handleTagAdd = useCallback((tag: Tag) => {
        if (taskData) {
            updateTask(taskData.id, { metadata: { source: "tag", value: tag } })
                .then((change: TaskData | null) => {
                    if (change) {
                        setTaskDetail({
                            ...taskDetail,
                            metadata: [...taskDetail.metadata, tag],
                        })
                    }
                })
                .catch(handleLogout());
        }
    }, [taskData, taskDetail]);

    const handleDeleteTag = useCallback((tag: Tag) => {
        if (taskData) {
            updateTask(taskData.id, { removeMetadata: { value: tag } }).then(() => {
                setTaskDetail({
                    ...taskDetail,
                    metadata: taskDetail.metadata.filter((t) => {
                        if ("component" in t && "component" in tag) {
                            return t.component !== tag.component;
                        }
                        if ("label" in t && "label" in tag) {
                            return t.label !== tag.label;
                        }
                        return true;
                    }),
                })
            })
                .catch(handleLogout());
        }
    }, [taskDetail, taskData]);

    const taskSearchFilter = useCallback((search: string): Option[] => {
        if (taskData === null) {
            return [];
        }

        const otherTasks = tasks.filter((task: TaskData) => task.id !== taskData.id);

        if (search === "") {
            return otherTasks.map((task, index) => { return { id: index, value: task.id, label: task.slug }; })
        }
        return filterTasksBySubstrings(otherTasks, search)
            .map((task, index) => { return { id: index, value: task.id, label: task.slug }; });
    }, [tasks, taskData]);

    const runEnabled = false;

    const onSetDate = useCallback(async (dateString: string | null) => {
        if (taskData?.id && dateString != taskData?.dueDate) {
            const task = await updateTask(taskData.id, { changeDueDate: { dueDate: dateString } });
            if (task) {
                updateTaskItem(task);
            }
        }
    }, [taskData?.id, taskData?.dueDate, updateTaskItem]);

    const onSetPriority = useCallback(async (value: number) => {
        if (taskData?.id && value != taskData?.priority) {
            const task = await updateTask(taskData.id, { changePriority: { value } });
            if (task) {
                updateTaskItem(task);
            }
        }
    }, [taskData?.id, taskData?.priority, updateTaskItem]);

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <h2 className="text-center mb-2">Description</h2>
            <DetailContent
                text={description}
                editMode={editMode}
                enableAutoFocus={true}
                onChange={handleDescriptionChange}
            />
            {taskData && <TaskComments taskId={taskData.id} />}
        </DetailContentArea>
    ) : (
        <DetailContentArea id="taskDetailSidebar">
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    <Personelle className="m-auto" userData={taskData?.author} tag="author" />
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={taskData?.assignee}
                        tag="assignee"
                        className="m-auto"
                        userSelectionShortList={assignableUsers}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <TaskState
                state={taskDetail.state}
                validTransitions={taskDetail.validTransitions}
                handleSetState={handleSetState}
            />
            <Priority value={taskData ? taskData.priority : 0} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due"
                date={taskData?.dueDate}
                onCompleted={onSetDate}
            />
            {taskDetail.plan && <PlannedTask plan={taskDetail.plan} />}
            {milestone && <Milestone milestone={milestone} />}
            <LinkState
                outLinks={taskDetail.dependencies}
                inLinks={taskDetail.linksIn}
            />
            <TagState
                metadata={taskDetail.metadata}
                handleAddTag={handleTagAdd}
                handleDeleteTag={handleDeleteTag}
            />
            <h2 className="text-center mb-2">Description</h2>
            <DetailContent
                text={description}
                editMode={editMode}
                enableAutoFocus={true}
                onChange={handleDescriptionChange}
            />
            {taskData && <TaskComments taskId={taskData.id} />}
        </DetailContentArea >
    );

    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel id="taskDetailSidebar">
            <div className="m-2 items-center justify-between rounded-lg border p-4">
                <Floater content="Author of this task">
                    <Personelle userData={taskData?.author} tag="author" />
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={taskData?.assignee}
                        tag="assignee"
                        userSelectionShortList={assignableUsers}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <TaskState
                state={taskDetail.state}
                validTransitions={taskDetail.validTransitions}
                handleSetState={handleSetState}
            />
            <Priority value={taskData ? taskData.priority : 0} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due"
                date={taskData?.dueDate}
                onCompleted={onSetDate}
            />
            {taskDetail.plan && <PlannedTask plan={taskDetail.plan} />}
            {milestone && <Milestone milestone={milestone} />}
            <LinkState
                outLinks={taskDetail.dependencies}
                inLinks={taskDetail.linksIn}
            />
            <TagState
                metadata={taskDetail.metadata}
                handleAddTag={handleTagAdd}
                handleDeleteTag={handleDeleteTag}
            />
        </DetailSidePanel>
    ) : <div />;

    const [popOverLink, setPopOverLink] = useState<string | null>(null);
    const triggerRef = React.createRef<HTMLButtonElement>();
    const handleSubmitLink = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        const formElements = form.elements;
        const linkType = (formElements[1] as HTMLSelectElement).value;
        const linkTo = popOverLink;

        if (linkType && linkTo) {
            updateTask(taskData?.id, { link: { taskId: linkTo, linkType } }).then(() => {
                const task = tasks.find((task) => task.id === linkTo);
                if (task) {
                    updateTaskItem(task);
                    setThisTaskCache(Date.now()); // Force a refresh
                }
            });
            triggerRef.current?.click();
        }
    }, [popOverLink, taskData?.id, tasks, updateTaskItem, triggerRef]);

    return (
        <DetailCard
            title={taskData ? taskData.title : "Invalid task"}
            titleIcon={<FaTasks />}
            editable={true}
            onSetTitle={onChangeTitle}
            onClose={vanish}
        >
            <DetailGrid narrow={narrow}>
                <DetailControls id="taskControlPanel">
                    {runEnabled && <DetailControl icon={<FaRobot />} text="Run" onClick={() => {
                        if (taskData) {
                            runTask(taskData.id)
                        }
                    }} />}
                    <Floater content="Edit task description">
                        <DetailControl
                            icon={editMode ? <FaFloppyDisk /> : <FaEdit />}
                            text={editMode ? "Save" : "Edit"}
                            onClick={editToggleSave}
                        />
                    </Floater>
                    <Floater content="View code for this project">
                        <DetailControl
                            icon={<FaCodeBranch />}
                            text="Code"
                            onClick={openCodeLink}
                        />
                    </Floater>
                    <Popover>
                        <PopoverTrigger ref={triggerRef}>
                            <Floater content="Create a link to another task">
                                <DetailControl icon={<FaLink />} text="Links" />
                            </Floater>
                        </PopoverTrigger>
                        <PopoverContent className="w-80 bg-neutral-50 dark:bg-neutral-950">
                            <div className="p-4 grid grid-gap-4">
                                <form onSubmit={handleSubmitLink} className="grid grid-cols-3 items-center gap-4">
                                    <Select>
                                        <SelectTrigger className="bg-neutral-50 text-neutral-600 col-span-3">
                                            <SelectValue placeholder="Link Type" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-neutral-50 text-neutral-600 col-span-3">
                                            <SelectGroup>
                                                <SelectItem className="select__hover" value="dependsOn">Depends On</SelectItem>
                                                <SelectItem className="select__hover" value="subtaskOf">Subtask Of</SelectItem>
                                                <SelectItem className="select__hover" value="relatedTo">Related To</SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <EditSearch textLabel="Link to" searchFrom={taskSearchFilter} onSelect={setPopOverLink} mustSelect={true} />
                                    <Button className="btn col-span-3" type="submit">Create</Button>
                                </form>
                            </div>
                        </PopoverContent>
                    </Popover>
                    {isOrgPlanner(loggedInUser) && <Floater content="Run an estimate for this task's duration">
                        <DetailControl icon={<CgSmartphoneChip />} text="Smart Estimate" onClick={() => {
                            if (taskData) {
                                replanTask(taskData.id).then(() => {
                                    getTaskDetail(taskData.id)
                                        .then((detail) => setTaskDetail(detail))
                                        .catch(handleLogout(() => console.error("Could not fetch task detail")));
                                });
                            }
                        }} />
                    </Floater>
                    }
                    <div className="flex-grow" />
                    {taskData && taskData.abstractState === "closed" && (
                        <div className="flex">
                            <Floater content="Reopen this task">
                                <DetailControl
                                    icon={<FaRecycle />}
                                    text="Reopen"
                                    onClick={() => {
                                        restartTask(taskData);
                                        getTaskDetail(taskData.id)
                                            .then((detail) => setTaskDetail(detail))
                                            .catch(handleLogout(() => console.error("Could not fetch task detail")));
                                    }}
                                />
                            </Floater>
                            <Floater content="Archive this task, it will not be accessible">
                                <DetailControl
                                    icon={<FaArchive />}
                                    text="Archive"
                                    onClick={() => {
                                        archiveTask(taskData);
                                    }}
                                />
                            </Floater>
                        </div>
                    )}
                </DetailControls>
                {DetailSidePanelMode}
                {DetailContentMode}
            </DetailGrid>
        </DetailCard>
    );
}
export default TaskDetail;
