import React from "react";
import AppPage from "./AppPage";
import { fetchNotifications, markAllNotificationsRead, Notification } from "./Notification";
import { isDevelopment, useTasksContext, useUsersContext } from "../contexts";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => {
    const { tasks } = useTasksContext();
    const { users } = useUsersContext();
    const highlightClass = notification.read ? "" : " notification__item--highlight";
    const date = new Date(notification.created + "Z");
    const navigate = useNavigate();

    let message = "";
    let link = "/";

    console.log(notification.channel, notification.message);

    if (notification.channel === "invite" && typeof notification.message === "object" && "orgId" in notification.message) {
        message = `You have been invited to join ${notification.message.orgName} by ${notification.message.invitedBy.username}`;
        link = "/app/invite"
    } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "assignment" in notification.message) {
        const taskId = notification.message.assignment.taskId;
        const userId = notification.message.assignment.from;
        const task = tasks.find((task) => task.id === taskId);
        const user = users.find((user) => user.id === userId);

        message = `${user?.username} assigned you to ${task?.title}`;
        link = "/app/tasks/" + task?.slug;

    } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "comment" in notification.message) {
        const taskId = notification.message.comment.taskId;
        const userId = notification.message.comment.from;
        const task = tasks.find((task) => task.id === taskId);
        const user = users.find((user) => user.id === userId);

        message = `${user?.username} commented on ${task?.title}`;
        link = "/app/tasks/" + task?.slug;

    } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "update" in notification.message) {
        const taskId = notification.message.update.taskId;
        const userId = notification.message.update.from;
        const update = notification.message.update.update;

        const task = tasks.find((task) => task.id === taskId);
        const user = users.find((user) => user.id === userId);
        message = `${user?.username} updated ${task?.title}: ${update}`;
        link = "/app/tasks/" + task?.slug;

    } else if (notification.channel === "result" && typeof notification.message === "string") {
        message = notification.message;
    } else if (notification.channel === "test") {
        message = "Test message"
    }

    const onClickNotification = () => {
        navigate(link);
    }

    return (
        <div className={"notification__item" + highlightClass} onClick={onClickNotification}>
            <div className="notification__message">{message}</div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
};

export default function NotificationPage() {
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    React.useEffect(() => {
        if (isDevelopment) {
            const Bill = {
                id: "1",
                created: "1954-01-01",
                username: "Bill Bezos",
                email: "bill@bees.biz",
                jobTitle: "Beemaster",
            };
            setNotifications([
                { id: "1", channel: "invite", message: { orgName: "Bill's Bees", orgId: "2", invitedBy: Bill }, read: false, created: "2024-01-01T00:00:00" },
                { id: "2", channel: "test", message: { orgName: "Bill's Knees", orgId: "4", invitedBy: Bill }, read: true, created: "2023-12-01T11:59:59" },
            ]);
            return;
        }

        fetchNotifications(1).then((notifications) => {
            setNotifications(notifications);
            markAllNotificationsRead();
        });
    }, []);

    return (
        <AppPage active="notifications">
            <div className="flex-col ml-auto mr-auto p-2">
                <h1 className="mb-4 w-full text-center">Notifications</h1>
                <div className="notification__container">
                    {notifications.map((notification) => (
                        <NotificationItem key={notification.id} notification={notification} />
                    ))}
                </div>
            </div>
        </AppPage>
    );
}
