import { useParams } from 'react-router-dom';
import UserDetail from './UserDetail';
import UserInvite from './UserInvite';

function UserDetailsView() {
    const { id } = useParams();
    if (id) {
        if (id === "invite") {
            return <UserInvite />;
        }
        return <UserDetail id={id} />;
    } else {
        return null;
    }
}
export default UserDetailsView;
