import React from 'react';
import { eventEmitter, isDevelopment, useUsersContext, useProjectsContext } from '../contexts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Invite, inviteList, inviteResponse, verifyEmail } from '../contexts/User';
import AppPage from './AppPage';
import ReactTimeAgo from 'react-time-ago';
import Personelle from './Personelle';

const InviteItem: React.FC<{ invite: Invite, triggerUpdate: () => void }> = ({ invite, triggerUpdate }) => {
    const date = new Date(invite.created + "Z");

    const onAccept = () => {
        inviteResponse(invite.id, true).then(triggerUpdate);
    };
    const onReject = () => {
        inviteResponse(invite.id, false).then(triggerUpdate);
    };

    return <div className="notification__item cursor-default">
        <div className="m-8 notification__message flex flex-row justify-between items-center">
            You were invited to join {invite.orgName}
            <ReactTimeAgo className="ml-2" date={date} />
            <div className="flex-grow" />
            <Personelle userData={invite.invitedBy} tag="Invited by" />
        </div>
        <div className="flex">
            <button className="editable__button ml-4" onClick={onAccept}>Accept</button>
            <button className="editable__button bg-red-600 dark:bg-hellotrope-400 dark:text-white" onClick={onReject}>Reject</button>
        </div>
    </div>;
};

const InvitePage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { refreshProjects, clearProjectCache } = useProjectsContext();
    const { clearUserCache } = useUsersContext();
    const navigate = useNavigate();
    const [invites, setInvites] = React.useState<Invite[]>([]);

    const updateInvites = React.useCallback(() => {
        inviteList().then((invites) => {
            setInvites(invites);
            if (invites.length === 0) {
                refreshProjects();
                navigate('/app/projects');
            }
        });
    }, [navigate, refreshProjects]);

    React.useEffect(() => {
        if (isDevelopment) {
            setInvites([{
                id: "1",
                orgName: "Bill's Bees",
                invitedBy: {
                    id: "3",
                    username: "Bill Bezos",
                    email: "bill@bees.biz",
                    created: "2024-07-01T00:00:00",
                    jobTitle: "CEO"
                },
                created: "2024-07-01T00:00:00"
            }]);
            return;
        }
        updateInvites();
    }, [updateInvites]);

    const triggerUpdate = () => {
        updateInvites();
        clearUserCache();
        clearProjectCache();
    };

    React.useEffect(() => {
        const token = searchParams.get('id');
        if (token) {
            verifyEmail(token).then((data) => {
                if (data.message === 'verified') {
                    eventEmitter.emit('INSTRUCT-MESSAGE', { "role": "system", "message": "Email verified" });
                    navigate('/');
                } else {
                    eventEmitter.emit('ERROR', "Sorry that verification link is invalid");
                }
            })
                .catch(() => {
                    eventEmitter.emit('ERROR', "Something went wrong");
                });
        }
    }, [navigate, searchParams]);

    return <AppPage active="invite">
        <div className="flex-col ml-auto mr-auto p-8 w-full">
            <h1 className="mb-4 w-full text-center">Invites</h1>
            <div className="notification__container">
                {invites.map((invite) => (
                    <InviteItem key={invite.id} invite={invite} triggerUpdate={triggerUpdate} />
                ))}
            </div>
        </div>
    </AppPage>;
};
export default InvitePage;
