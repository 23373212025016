import Instructions from "./Instructions";
import OmniBar from "./OmniBar";
import SideBar from "./SideBar";

function WorklogPage() {
    return (
        <div className="app-grid">
            <div className="main-container">
                <Instructions key="conversation" />
            </div>
            <OmniBar key="omnibar" />
            <SideBar key="sidebar" active="tasks" />
        </div>
    );
}
export default WorklogPage;
