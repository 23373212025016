import { Milestone as MilestoneData } from "../../../contexts/Task";
import { InfoIcon } from "../../Floater";

interface MilestoneProps {
    milestone: MilestoneData;
}

function Milestone({ milestone }: MilestoneProps) {
    return (<div className="text-left m-2 items-center justify-between rounded-lg border p-4">
        <div className="flex"><h1>Milestone</h1>
        <div className="flex-grow" />
        <InfoIcon className="mt-2 omnibar__icon text-sm" content="Milestones can be used to represent events, timelines, schedules..." /></div>
        <h2>{milestone.name}</h2>
        <h4>Type: {milestone.milestoneType}</h4>
        <p>{milestone.description}</p>
    </div>);
}
export default Milestone;
