import { useEffect, useState } from "react";
import { useMilestoneContext } from "../../../contexts";
import { Milestone } from "../../../contexts/Milestone";
import { useNavigate } from "react-router-dom";
import DetailGrid, { DetailContentArea } from "../DetailGrid";
import ReactTimeAgo from "react-time-ago";
import DetailCard from "../DetailCard";
import { MilestoneIcon } from "lucide-react";
import { Label } from "../../utils/Label";

interface MilestoneDetailProps {
    id: string
}

function MilestoneDetail({ id }: MilestoneDetailProps) {
    const { getMilestone } = useMilestoneContext();
    const [data, setData] = useState<Milestone | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getMilestone(id).then((milestone) => setData(milestone));
    }, [id, getMilestone]);

    const vanish = () => {
        navigate(-1);
    };

    if (!data) {
        return null;
    }
    const createdDate = new Date(data.created + "Z");

    return (
        <DetailCard
            title={data.name}
            titleIcon={<MilestoneIcon size={24} />}
            onClose={vanish} >
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <div className="mb-2 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-4">
                        <Label className="detail-grid__label">Created</Label>
                        <ReactTimeAgo className="mr-2" date={createdDate} />
                    </div>
                    {data.startDate && <div className="mb-2 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-4">
                        <Label className="detail-grid__label">Started</Label>
                        <ReactTimeAgo className="mr-2" date={new Date(data.startDate + 'Z')} />
                    </div>}
                    {data.dueDate && <div className="mb-2 w-64 mr-2 flex m-2 items-center justify-between rounded-lg border p-4">
                        <Label className="detail-grid__label">Due</Label>
                        <ReactTimeAgo className="mr-2" date={new Date(data.dueDate + 'Z')} />
                    </div>}
                    <div className="m-2 items-center justify-between rounded-lg border p-4">
                        <Label className="detail-grid__text">{data.milestoneType}</Label>
                        <Label className="mt-2 detail-grid__text">{data.description}</Label>
                    </div>
                </DetailContentArea>
            </DetailGrid>
        </DetailCard>
    );
}
export default MilestoneDetail;
