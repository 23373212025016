import { FaXmark, FaRegFloppyDisk, FaRegNewspaper } from "react-icons/fa6";
import { FaProjectDiagram, FaEdit } from "react-icons/fa";
import React, { useState } from "react";
import { ExistingProject, trackerOptions } from "../../../contexts/Project";
import Personelle from "../../Personelle";
import { useAppContext, useProjectsContext } from "../../../contexts";
import DetailGrid, {
    DetailContent,
    DetailControls,
    DetailControl,
    DetailContentArea,
} from "../DetailGrid";
import { useNavigate, useSearchParams } from "react-router-dom";

function ProjectCreate() {
    const { loggedInUser } = useAppContext();
    const { addProject } = useProjectsContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const modalRef = React.createRef<HTMLDivElement>();
    const [previewState, setPreviewState] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [gitHubToken, setGitHubToken] = useState("");
    const [existingProject, setExistingProject] = useState<ExistingProject | null>(null);

    if (loggedInUser == null) {
        return null;
    }

    const vanish = () => {
        navigate(-1);
    };

    function togglePreview() {
        setPreviewState(!previewState);
    }

    function saveProject() {
        const projectSource = searchParams.get("projectSource");
        addProject({
            name: name,
            description: description,
            githubToken: gitHubToken,
            existingProject: existingProject,
            projectSource
        });
        vanish();
    }

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaProjectDiagram />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create a Project</h2>
                </div>
                <div className="flex-grow" />
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailControls>
                    <DetailControl
                        icon={previewState ? <FaEdit /> : <FaRegNewspaper />}
                        text={previewState ? "Edit" : "Preview"}
                        onClick={togglePreview}
                    />
                    <DetailControl
                        icon={<FaRegFloppyDisk />}
                        text="Save"
                        onClick={saveProject}
                    />
                </DetailControls>
                <DetailContentArea>
                    <div className="onboarding__form">
                        <label id="nameLabel" className="onboarding__label">Project Name</label>
                        <input id="name" autoFocus className="onboarding__input" type="text" value={name} onChange={(e) => setName(e.target.value)} />

                        <label id="tokenLabel" className="onboarding__label">Github API Token</label>
                        <input id="token" className="onboarding__input" type="text" value={gitHubToken} onChange={(e) => setGitHubToken(e.target.value)} />

                        <label id="repoLabel" className="onboarding__label">Repo (If Any)</label>
                        <input id="repo" className="onboarding__input" type="text" value={existingProject?.repo} onChange={(e) => setExistingProject({
                            repo: e.target.value, projectTracker: existingProject?.projectTracker || "zini"
                        })} />

                        <label id="tracker_label" className="onboarding__label">Issue Tracker (If Any)</label>
                        <select id="tracker" className="onboarding__input" value={existingProject?.projectTracker} onChange={(e) => setExistingProject({
                            projectTracker: e.target.value, repo: existingProject?.repo || ""
                        })} >
                            {trackerOptions.map((option) => (
                                <option key={option.id} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="ml-auto mr-2">
                        <Personelle userData={loggedInUser} tag="author" />
                    </div>
                    <label id="description_label" className="onboarding__label mt-2">Project Description</label>
                    <DetailContent
                        text={description}
                        editMode={!previewState}
                        enableAutoFocus={false}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </DetailContentArea>
            </DetailGrid>
        </div>
    );
}

export default ProjectCreate;
