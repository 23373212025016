import TaskDetail from "./TaskDetail.tsx";
import TaskCreate from "./TaskCreate.tsx";
import { useParams } from "react-router-dom";

function TaskDetailsView() {
    const { slug } = useParams();
    if (slug === "create") {
        return <TaskCreate />;
    } else if (slug) {
        return <TaskDetail slug={slug} />;
    } else {
        return null;
    }
}

export default TaskDetailsView;
