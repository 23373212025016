import { FaInfoCircle } from "react-icons/fa";
import { Tooltip, TooltipContent, TooltipTrigger } from "./utils/Tooltip";

interface InputBarIconProps {
    content: string;
    className?: string;
}

export const InfoIcon = ({ content, className }: InputBarIconProps) => {
    let baseClassName = "omnibar__icon";
    if (className) {
        baseClassName = className;
    }
    return <Floater content={content}>
        <FaInfoCircle className={baseClassName} />
    </Floater>;
};

interface Props {
    content: string;
    children: React.ReactNode;
}

const Floater = ({ content, children }: Props) => {
    return <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent><p>{content}</p></TooltipContent>
    </Tooltip>;
};
export default Floater;
