import { ClassNamesConfig, Theme } from "react-select";
import { Config } from "tailwindcss";
import { DisplayTheme } from "../contexts/AppContext";

type DarkColorTheme = {
    "aquamarine": { "400": string },
    "hellotrope": { "400": string },
    "spacecadet": { "700": string },
    "night": { "900": string, "600": string },
    "rufous": { "400": string, "600": string }
};

type LightColorTheme = {
    "cerulean": { "300": string, "600": string },
    "verdigris": { "700": string, "300": string, "500": string },
    "vanilla": { "100": string, "300": string },
    "vermillion": { "700": string }
}

type ColorTheme = {
    primary: string;
    danger: string;
    dangerLight: string;
    white: string;
    neutral: string;
};

function colorSelect(displayTheme: DisplayTheme, colors: DarkColorTheme | LightColorTheme): ColorTheme {
    if (displayTheme === "dark") {
        const dark = colors as DarkColorTheme;
        return {
            primary: dark.night["900"],
            danger: dark.night["900"],
            dangerLight: dark.hellotrope["400"],
            white: "white",
            neutral: dark.aquamarine["400"]
        };
    } else {
        const light = colors as LightColorTheme;
        return {
            primary: "white",
            danger: light.cerulean["600"],
            dangerLight: light.vermillion["700"],
            white: "black",
            neutral: light.cerulean["600"]
        };
    }
}

interface ReactSelectThemeReturnProps {
    classes: ClassNamesConfig,
    selectTheme: (theme: Theme) => Theme,
}

function reactSelectTheme(displayTheme: DisplayTheme, config: Config): ReactSelectThemeReturnProps {
    const colors = config.theme?.extend?.colors;
    if (colors === null) {
        throw Error("Missing tailwind config");
    }
    const themeColors = displayTheme === "dark" ? colors as DarkColorTheme : colors as LightColorTheme;
    const selectThemeBase = colorSelect(displayTheme, themeColors);

    const classes = {
        clearIndicator: () => "select__background",
        container: () => "select__container",
        control: () => "select__background",
        dropdownIndicator: () => "select__background",
        group: () => "select__background",
        groupHeading: () => "select__background",
        indicatorSeparator: () => "select__background",
        indicatorsContainer: () => "select__background",
        input: () => "select__background",
        loadingIndicator: () => "select__background",
        loadingMessage: () => "select__background",
        menu: () => "select__background",
        menuList: () => "select__background",
        menuPortal: () => "select__background",
        multiValue: () => "select__background",
        multiValueLabel: () => "select__label",
        multiValueRemove: () => "select__label",
        noOptionsMessage: () => "select__background",
        option: () => "select__background",
        placeholder: () => "select__background",
        singleValue: () => "select__background",
        valueContainer: () => "select__background",
    };

    const theme = (theme: Theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary: selectThemeBase.white,
            primary25: selectThemeBase.primary,
            primary50: selectThemeBase.primary,
            primary75: selectThemeBase.primary,
            danger: selectThemeBase.danger,
            dangerLight: selectThemeBase.dangerLight,
            neutral0: selectThemeBase.white,
            neutral5: selectThemeBase.white,
            neutral10: selectThemeBase.white,
            neutral20: selectThemeBase.white,
            neutral30: selectThemeBase.neutral,
            neutral40: selectThemeBase.neutral,
            neutral50: selectThemeBase.white,
            neutral60: selectThemeBase.white,
            neutral70: selectThemeBase.white,
            neutral80: selectThemeBase.white,
            neutral90: selectThemeBase.white,
        },
    });

    return { classes, selectTheme: theme };
}
export default reactSelectTheme;
