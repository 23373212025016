import React, { useEffect } from 'react';
import OmniBar from './OmniBar';
import SideBar from './SideBar';
import Instructions from './Instructions';
import Tour from "./Tour";
import { eventEmitter, useInstructionContext } from '../contexts';
import { login, useAppContext } from '../contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toaster } from './utils/Toaster';
import { toast } from 'sonner';

const NO_AUTH_PAGES = [
    '/app/beta-invite',
    '/app/beta',
    '/app/onboarding',
    '/app/checkout',
    '/app/checkout/complete',
    '/app/down',
];

const LOGGED_IN_ROLES = [
    'active',
    'admin',
];

const AppPage: React.FC<{ active: string, children: React.ReactNode }> = ({ active, children }) => {
    const { loggedInUser, loginAttempted, narrow } = useAppContext();
    const { conversationOpen, setConversationOpen } = useInstructionContext();
    const navigate = useNavigate();
    const location = useLocation();

    const basePath = location.pathname.split('?')[0];
    const onNoAuthPage = NO_AUTH_PAGES.includes(basePath);

    useEffect(() => {
        eventEmitter.on('ERROR', toast.error)
        return () => {
            eventEmitter.off('ERROR', toast.error)
        }
    }, [])

    useEffect(() => {
        if (conversationOpen && narrow && location.pathname !== '/app/chat') {
            setConversationOpen(false);
            navigate("/app/chat");
        }

        if (!narrow && location.pathname === '/app/chat') {
            setConversationOpen(true);
            navigate(-1);
        }
    }, [conversationOpen, setConversationOpen, navigate, narrow, location]);

    if (!onNoAuthPage) {
        if (!loginAttempted) {
            return;
        }

        if (!loggedInUser) {
            login();
            return;
        }

        const validatedUser = loggedInUser?.role && LOGGED_IN_ROLES.includes(loggedInUser.role);

        if (!validatedUser) {
            navigate('/app/verify-email');
        }
    }

    return (
        <div key="grid" className="app-grid">
            <OmniBar key="omnibar" />
            <div className="main-container">
                {!narrow && <Instructions key="conversation" />}
                {children}
            </div>
            <SideBar key="sidebar" active={active} />
            <Tour key="tour" />
            <Toaster />
        </div>
    );
}
export default AppPage;
