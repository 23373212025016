import React, { memo, useRef } from "react";
import { Chart } from "../../../contexts/Chart";
import { ListChildComponentProps, areEqual } from "react-window";
import { FaRegChartBar } from "react-icons/fa6";
import Personelle from "../../Personelle";
import ReactTimeAgo from "react-time-ago";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, handleClick } = data;
    const dataItem = items[index];
    if (!dataItem) {
        return null;
    }
    const height = style.height as number;

    const dynamicStyle = {
        ...style,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniChart
        key={`data-${dataItem.id}`}
        handleClick={() => handleClick(dataItem.planId)}
        card={dataItem}
        style={dynamicStyle}
    />);
}, areEqual);

interface MiniChartProps {
    handleClick: () => void;
    card: Chart;
    style: React.CSSProperties
}

function MiniChart({ handleClick, card, style }: MiniChartProps) {
    const ref = useRef<HTMLDivElement>(null);
    const startedDate = new Date(card.metadata.startDate + "Z");
    const title = card.projects.map(p => p.name).join(", ");

    return (
        <div ref={ref} style={style} onClick={handleClick} className="card">
            <div className="card__top-bar">
                <div className="card__icon">
                    <FaRegChartBar />
                </div>
                <div className="card-header">{title}</div>
            </div>
            <Personelle userData={card.createdBy} tag="Plan started by" />
            <div className="flex">
                <div className="flex-grow" />
                <ReactTimeAgo className="mr-2" date={startedDate} />
            </div>
        </div>
    );
}
